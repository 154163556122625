import { AppStore } from "@/store";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { CollectionsFilterMode } from "@/store/note/types";

export function getSelectedCollectionTitles(
  store: AppStore,
  collectionIds: string[],
  mode?: CollectionsFilterMode,
  noCollectionIsSelected?: boolean
): string[] {
  const collectionTitles = collectionIds
    .map(id => store.collections.getCollectionObservableById({ collectionId: id }))
    .map(collection => collection?.title || UNTITLED_COLLECTION_TITLE);

  if (mode !== "all" && noCollectionIsSelected) {
    collectionTitles.unshift("Not in any collection");
  }

  return collectionTitles;
}
