import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import {
  MdsItemListItemRowData,
  MdsItemListRowType,
} from "@/design-system/components/item-list/types/MdsItemListRowTypes";
import { MdsItemKind } from "@/design-system/components/item-list/types/MdsItemTypes";
import {
  MdsItemDropdown,
  MdsItemListSize,
} from "@/design-system/components/item-list/types/SharedTypes";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { generateShortDateString } from "@/domains/date/date";
import { AppStore } from "@/store/AppStore";
import { INoteObservable } from "@/store/note";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { DateTime } from "luxon";

export const getRowForNoteObservable = ({
  dateTime,
  dropdown,
  inMainPanel,
  listState,
  noteObservable,
  store,
  trackClick,
}: {
  dateTime: Date | string;
  dropdown: MdsItemDropdown;
  inMainPanel: boolean;
  listState?: ListStateObservable;
  noteObservable: INoteObservable;
  store: AppStore;
  trackClick?: () => void;
}) => {
  const noteId = noteObservable.id;
  const dateLabel = generateShortDateString(
    typeof dateTime === "string" ? DateTime.fromISO(dateTime) : DateTime.fromJSDate(dateTime)
  );

  const row: MdsItemListItemRowData = {
    type: MdsItemListRowType.Item,
    key: noteObservable.id,
    size: MdsItemListSize.XLarge,
    payload: {
      id: noteObservable.id,
      kind: MdsItemKind.Note,
      createPreviewState: () =>
        new ItemPreviewState({
          store,
          id: noteId,
          kind: MdsItemKind.Note,
        }),
      label: noteObservable.title,
      onClick: () => {
        store.navigation.goToNote({ noteId });
        trackClick?.();
      },
      sharedBy: noteObservable.sharedBy,
      dateLabel,
      dropdown,
      listState,
      extraRows: [
        {
          id: `note-${noteId}-content`,
          content: () => (
            <MdsItemListRowFeaturedContent
              collections={sortCollectionsForChips(
                noteObservable.collectionList?.allCollections || []
              )}
              snippet={
                noteObservable.collectionList?.allCollections.length
                  ? undefined
                  : [{ text: noteObservable.secondaryTitle || "No additional text" }]
              }
            />
          ),
        },
      ],
    },
  };
  if (!inMainPanel) {
    row.payload.extraRows = [
      {
        id: `note-${noteId}-content`,
        content: () => <MdsItemListRowFeaturedContent snippet={[{ text: dateLabel }]} />,
      },
    ];
    row.payload.onClick = () => store.sidePanel.goToNote({ noteId });
    row.payload.sharedBy = undefined;
    row.payload.dateLabel = undefined;
    row.payload.dropdown = undefined;
    row.payload.listState = undefined;
  }
  return row;
};
