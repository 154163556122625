import { uuidModule } from "@/modules/uuid";
import {
  ChatConversationModelData,
  ChatConversationUpsertedSyncUpdateValue,
} from "@/store/chat/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { ICreateChatConversationOperation } from "@/store/sync/operations/types";
import { SyncModelData, SyncOperationKind, OptimisticSyncUpdate } from "@/store/sync/types";

export class CreateChatConversationOperation extends BaseSyncOperation<ICreateChatConversationOperation> {
  get operationKind(): SyncOperationKind {
    return "CREATE_CHAT_CONVERSATION";
  }

  generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[] {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];
    const chatCreationUpdate = this.generateCreateChatConversationSyncUpdate();
    if (chatCreationUpdate) {
      optimisticUpdates.push(chatCreationUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.chatConversations.recompute(this.payload.id);
  }

  protected generateCreateChatConversationSyncUpdate(): OptimisticSyncUpdate<ChatConversationModelData> {
    const chatKind = this.payload.kind ?? "GLOBAL";
    const value: ChatConversationUpsertedSyncUpdateValue = {
      model_id: this.payload.id,
      model_kind: "CHAT_CONVERSATION",
      model_version: 0,
      model_data: {
        owned_by_space_account_id: this.store.spaceAccounts.myPersonalSpaceAccountId,
        is_primary_chat_conversation: chatKind === "GLOBAL",
        kind: chatKind,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const syncUpdate: OptimisticSyncUpdate<ChatConversationModelData> = {
      optimistic_update_id: uuidModule.generate(),

      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    return syncUpdate;
  }
}
