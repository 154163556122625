import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  override,
  runInAction,
} from "mobx";
import { Uuid } from "@/domains/global/identifiers";
import { AppSubStoreArgs } from "@/store/types";
import { ContactModelData } from "@/store/contacts/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { OptimisticSyncUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { ContactObservable } from "@/store/contacts/ContactObservable";
import { resolveContactSyncModelUuid } from "@/modules/uuid/sync-models/resolveContactModelUuid";
import { IndexedBoolean, Maybe } from "@/domains/common/types";
import { Dexie, liveQuery, Subscription, Table } from "dexie";
import { ContactIndexData, ContactIndexes } from "@/store/contacts/ContactIndexes";
import { AppStore } from "@/store/AppStore";

type IndexedContactTuple = [is_direct: IndexedBoolean, model_id: Uuid];

export class AppStoreContactsStore extends BaseSyncModelStore<ContactObservable, ContactModelData> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.Contact, ...injectedDeps });
    makeObservable(this, {
      unsubscribeFromIndirectContacts: true,
      unsubscribeFromDirectContacts: true,
      localTable: override,
      computeIndexes: override,
      createSyncModel: false,

      initializeDirectContactsSubscription: action,
      directContactsSubscription: observable,
      directContactIds: observable,
      directContacts: computed,

      initializeIndirectContactsSubscription: action,
      indirectContactsSubscription: observable,
      indirectContactIds: observable,
      indirectContacts: computed,

      displayContacts: computed,

      getBySpaceAccountId: true,
      getMatchingContacts: false,
      isDeletedContact: false,
      isMyOwnContact: false,
    });

    onBecomeObserved(this, "directContactIds", () => this.initializeDirectContactsSubscription());
    onBecomeUnobserved(this, "directContactIds", () => this.unsubscribeFromDirectContacts());
    onBecomeObserved(this, "indirectContactIds", () =>
      this.initializeIndirectContactsSubscription()
    );
    onBecomeUnobserved(this, "indirectContactIds", () => this.unsubscribeFromIndirectContacts());
  }

  directContactsSubscription: Maybe<Subscription>;
  directContactIds: IndexedContactTuple[] = [];
  initializeDirectContactsSubscription() {
    this.directContactsSubscription?.unsubscribe();
    this.directContactsSubscription = liveQuery(() =>
      this.localTable
        .where("[is_direct+model_id]")
        .between([1, Dexie.minKey], [1, Dexie.maxKey])
        .keys()
    ).subscribe(ids => {
      runInAction(() => (this.directContactIds = ids as unknown as IndexedContactTuple[]));
    });
  }
  unsubscribeFromDirectContacts() {
    this.directContactsSubscription?.unsubscribe();
  }
  get directContacts(): ContactObservable[] {
    return this.directContactIds
      .map(([_is_direct, model_id]) => this.get(model_id))
      .filter(contact => !!contact);
  }

  indirectContactsSubscription: Maybe<Subscription>;
  indirectContactIds: IndexedContactTuple[] = [];
  initializeIndirectContactsSubscription() {
    this.indirectContactsSubscription?.unsubscribe();
    this.indirectContactsSubscription = liveQuery(() =>
      this.localTable
        .where("[is_direct+model_id]")
        .between([0, Dexie.minKey], [0, Dexie.maxKey])
        .keys()
    ).subscribe(ids => {
      runInAction(() => (this.indirectContactIds = ids as unknown as IndexedContactTuple[]));
    });
  }
  unsubscribeFromIndirectContacts() {
    this.indirectContactsSubscription?.unsubscribe();
  }
  get indirectContacts(): ContactObservable[] {
    return this.indirectContactIds
      .map(([_is_direct, model_id]) => this.get(model_id))
      .filter(contact => !!contact);
  }

  createSyncModel(data: SyncUpdateValue<ContactModelData>) {
    return new ContactObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public get localTable() {
    return this.db.mappedTables[this.modelKind].local as Table<
      SyncUpdateValue<ContactModelData> & ContactIndexData
    >;
  }

  getBySpaceAccountId(contactSpaceAccountId: Uuid): ContactObservable | undefined {
    const id = resolveContactSyncModelUuid({ spaceAccountId: contactSpaceAccountId });
    return this.get(id);
  }

  getMatchingContacts = async (query: string): Promise<ContactObservable[]> => {
    const needle = query.trim().toLowerCase();
    if (!needle) return [];
    const matchingContactIds = await this.localTable
      .filter(contact => contact.profile_display_name_and_email_address.includes(needle))
      .primaryKeys();
    const contacts = await Promise.all(matchingContactIds.map(id => this.get(id)));
    return contacts.filter(contact => !!contact);
  };

  public computeIndexes({
    store,
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<ContactModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<ContactModelData>[];
  }): Record<string, unknown> {
    return new ContactIndexes({ store, remoteData, optimisticUpdates }).indexes;
  }

  isDeletedContact(contact: ContactObservable): boolean {
    return (
      contact.profileEmailAddress.startsWith("contact-") &&
      contact.profileEmailAddress.endsWith("@mem.ai")
    );
  }

  isMyOwnContact(contact: ContactObservable): boolean {
    const mySpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    return contact.contactSpaceAccountId === mySpaceAccountId;
  }

  get displayContacts(): ContactObservable[] {
    return this.directContacts.filter(
      contact => !this.isDeletedContact(contact) && !this.isMyOwnContact(contact)
    );
  }
}
