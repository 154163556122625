import styled from "@emotion/styled";
import { Switch } from "react-aria-components";

export const StyledSwitch = styled(Switch)`
  display: flex;
  align-items: center;
  gap: 0.571rem;
  font-size: 1.143rem;
  color: ${({ theme }) => theme.colors.grey.x0};
  forced-color-adjust: none;

  .indicator {
    width: 2rem;
    height: 1.143rem;
    border: 2px solid ${({ theme }) => theme.colors.grey.x0};
    background: transparent;
    border-radius: 1.143rem;
    transition: all 200ms;

    &:before {
      content: "";
      display: block;
      margin: 1px;
      width: 0.75rem;
      height: 0.75rem;
      background: ${({ theme }) => theme.colors.grey.x0};
      border-radius: 16px;
      transition: all 200ms;
    }
  }

  &[data-pressed] .indicator {
    border-color: ${({ theme }) => theme.colors.grey.x0};

    &:before {
      background: ${({ theme }) => theme.colors.grey.x0};
    }
  }

  &[data-selected] {
    .indicator {
      border-color: ${({ theme }) => theme.colors.grey.x0};
      background: transparent;

      &:before {
        background: ${({ theme }) => theme.colors.grey.x0};
        transform: translateX(100%);
      }
    }

    &[data-pressed] {
      .indicator {
        border-color: ${({ theme }) => theme.colors.grey.x0};
        background: transparent;
      }
    }
  }

  &[data-focus-visible] .indicator {
    outline: 2px solid ${({ theme }) => theme.colors.blue.x400};
    outline-offset: 2px;
  }

  &[data-disabled] {
    color: ${({ theme }) => theme.colors.grey.x200};

    .indicator {
      border-color: ${({ theme }) => theme.colors.grey.x200};
      background-color: ${({ theme }) => theme.colors.grey.x0};

      &:before {
        background: ${({ theme }) => theme.colors.grey.x200};
      }
    }
  }
`;
