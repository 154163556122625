import { AppStore } from "@/store/AppStore";
import {
  SearchEngineRequestFacetFilter,
  SearchEngineRequestFacetFilterContainsMediaKind,
  SearchEngineRequestFacetFilterInCollection,
  SearchEngineRequestFacetFilterKind,
  SearchEngineRequestFacetFilterModifiedBy,
  SearchEngineRequestFacetFilterOwnedBy,
  SearchEngineRequestFilter,
  ValidSearchSortByValue,
} from "@/store/pages/SearchPageStore/types";
import { SearchEngineParams } from "@/modules/url-params/search-engine-params/types";
import { urlParamsModule } from "@/modules/url-params";
import { MdsDropdownItemKind, MdsDropdownContentList } from "@/design-system/components/dropdown";
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  reaction,
  runInAction,
} from "mobx";
import { getContactsFacetFilterItems } from "@/modules/filters/common/getContactsFacetFilterItems";
import { ContactsFacetFilterItem } from "@/components/filters/types";
import { CollectionSearchReturnType } from "@/store/collections/types";
import { SortByKind } from "@/modules/lenses/types";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { CollectionsFacetFilterItem } from "@/components/filters/CollectionsFacetFilter/types";
import { getSelectedCollectionTitles } from "@/modules/filters/common/getSelectedCollectionTitles";
import { MediaKindsFacetFilterItem } from "@/components/filters/MediaKindsFacetFilter/types";
import { getMediaKindsFacetFilterItems } from "@/modules/filters/common/getMediaKindsFacetFilterItems";
import { NoteMediaKind } from "@/store/note";

export type SearchPageSortByKind = SortByKind.LastModified | SortByKind.LastCreated;

export class SearchPageFilter {
  createdBySearchQuery: string = "";
  modifiedBySearchQuery: string = "";
  loadingCollections: boolean = false;
  collectionsSearchQuery: string = "";
  searchedAndLoadedCollections: CollectionObservable[] = [];

  constructor(private readonly store: AppStore) {
    makeObservable<this, "updateSearchEngineParams">(this, {
      params: computed,
      searchQuery: computed,
      updateSearchEngineParams: action,

      addFilter: action,
      removeFilter: action,
      addFacetFilter: action,
      removeFacetFilter: action,

      // SORTING
      sortOptions: computed,
      sortLabel: computed,
      setSortBy: action,

      // COLLECTIONS FILTER
      searchedAndLoadedCollections: observable,
      collectionsSearchQuery: observable,
      loadingCollections: observable,
      collectionsFacetFilterItems: computed,
      collectionsParam: computed,
      selectedCollectionTitles: computed,
      setCollectionSearchQuery: action,
      clearCollectionSearchQuery: action,
      toggleCollectionItem: action,
      clearCollections: action,
      initializeCollectionsSearch: action,

      // MEDIA_KINDS FILTER
      mediaKindsParam: computed,
      selectedMediaKinds: computed,
      mediaKindsFacetFilterItems: computed,
      clearMediaKinds: action,
      toggleMediaKindItem: action,

      // MODIFIED_BY FILTER
      modifiedBySearchQuery: observable,
      modifiedByParam: computed,
      modifiedByFacetFilterItems: computed,
      setModifiedBySearchQuery: action,
      clearModifiedBySearchQuery: action,
      toggleModifiedByItem: action,
      clearModifiedBy: action,

      // CREATED_BY FILTER
      createdBySearchQuery: observable,
      createdByParam: computed,
      createdByFacetFilterItems: computed,
      setCreatedBySearchQuery: action,
      clearCreatedBySearchQuery: action,
      toggleCreatedByItem: action,
      clearCreatedBy: action,
    });

    onBecomeObserved(this, "collectionsFacetFilterItems", () => this.initializeCollectionsSearch());
  }

  get params(): SearchEngineParams {
    return urlParamsModule.search.parse({
      searchQueryStr: this.store.navigation.activeSearchQuery,
    });
  }

  get searchQuery(): string {
    return urlParamsModule.search.stringify({ searchParams: this.params });
  }

  addFilter(filter: SearchEngineRequestFilter) {
    this.updateSearchEngineParams({
      filters: [...this.params.filters, filter],
    });
  }

  removeFilter(filter: SearchEngineRequestFilter) {
    const filters = this.params.filters.filter(f => f !== filter);
    this.updateSearchEngineParams({ filters });
  }

  addFacetFilter(filter: SearchEngineRequestFacetFilter) {
    this.updateSearchEngineParams({
      facetFilters: [...this.params.facetFilters, filter],
    });
  }

  removeFacetFilter(filterKind: SearchEngineRequestFacetFilterKind) {
    const facetFilters = this.params.facetFilters.filter(f => f.kind !== filterKind);
    this.updateSearchEngineParams({ facetFilters });
  }

  private updateSearchEngineParams({ filters, facetFilters, sortBy }: Partial<SearchEngineParams>) {
    const searchParams = {
      ...this.params,
    };

    if (filters) {
      searchParams.filters = filters;
    }

    if (facetFilters) {
      searchParams.facetFilters = facetFilters;
    }

    if (sortBy) {
      searchParams.sortBy = sortBy;
    }

    this.store.navigation.goToSearch({
      searchParams,
      config: {
        replace: true,
      },
    });
  }

  // SORTING
  get sortOptions(): MdsDropdownContentList {
    const sortBy = this.params.sortBy;
    return {
      items: [
        {
          id: "sort-by-divider",
          kind: MdsDropdownItemKind.Detail,
          text: "Sort by",
        },
        {
          id: "LAST_MODIFIED",
          kind: MdsDropdownItemKind.Button,
          label: "Last modified",
          isChecked: sortBy === SortByKind.LastModified,
          onClick: this.setSortBy,
        },
        {
          id: "LAST_CREATED",
          kind: MdsDropdownItemKind.Button,
          label: "Last created",
          isChecked: sortBy === SortByKind.LastCreated,
          onClick: this.setSortBy,
        },
      ],
    };
  }

  get sortLabel(): string {
    const selectedSortOption = this.sortOptions.items.find(e => e.id === this.params.sortBy);
    return selectedSortOption && "label" in selectedSortOption ? selectedSortOption.label : "-";
  }

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.updateSearchEngineParams({ sortBy: itemId as ValidSearchSortByValue });
  };

  // COLLECTIONS FILTER
  initializeCollectionsSearch() {
    reaction(
      () => [this.collectionsSearchQuery],
      async () => {
        runInAction(() => {
          this.loadingCollections = true;
        });

        const collectionIndexTuples = await this.store.collections.search({
          query: this.collectionsSearchQuery,
          limit: 100,
          sortBy: SortByKind.LastViewed,
          returns: CollectionSearchReturnType.CollectionIndexTuple,
        });

        const collections = await Promise.all(
          collectionIndexTuples.map(tuple => this.store.collections.getAsync(tuple[2]))
        ).then(results => results.filter(collection => !!collection));

        runInAction(() => {
          this.searchedAndLoadedCollections = collections;
          this.loadingCollections = false;
        });
      },
      { fireImmediately: true }
    );
  }

  get collectionsParam(): SearchEngineRequestFacetFilterInCollection | undefined {
    const filter = this.params.facetFilters.find(f => f.kind === "IN_COLLECTION");
    if (filter && "collection_ids" in filter.value) {
      return filter as SearchEngineRequestFacetFilterInCollection;
    }
    return undefined;
  }

  get collectionsFacetFilterItems(): CollectionsFacetFilterItem[] {
    return this.searchedAndLoadedCollections.map(collection => ({
      id: collection.id,
      title: collection.title || UNTITLED_COLLECTION_TITLE,
      subtitle: collection.description,
      isSelected: this.collectionsParam?.value.collection_ids.includes(collection.id),
      isVisible: true,
    }));
  }

  get selectedCollectionTitles(): string[] {
    return getSelectedCollectionTitles(
      this.store,
      this.collectionsParam?.value.collection_ids || []
    );
  }

  setCollectionSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value || "";
    this.collectionsSearchQuery = value;
  };

  clearCollectionSearchQuery = () => {
    this.collectionsSearchQuery = "";
  };

  toggleCollectionItem = (id: string) => {
    const selectedCollections = this.collectionsParam?.value.collection_ids || [];
    const newSelectedCollections = selectedCollections.includes(id)
      ? selectedCollections.filter(collectionId => collectionId !== id)
      : [...selectedCollections, id];

    this.clearCollections();
    this.addFacetFilter({
      kind: "IN_COLLECTION",
      value: { collection_ids: newSelectedCollections },
    });
  };

  clearCollections = () => {
    this.removeFacetFilter("IN_COLLECTION");
  };

  // MEDIA_KINDS FILTER
  get mediaKindsParam(): SearchEngineRequestFacetFilterContainsMediaKind | undefined {
    const filter = this.params.facetFilters.find(f => f.kind === "CONTAINS_MEDIA_KIND");
    if (filter && "media_kinds" in filter.value) {
      return filter as SearchEngineRequestFacetFilterContainsMediaKind;
    }
    return undefined;
  }

  get selectedMediaKinds(): NoteMediaKind[] {
    return (this.mediaKindsParam?.value.media_kinds as NoteMediaKind[]) || [];
  }

  get mediaKindsFacetFilterItems(): MediaKindsFacetFilterItem[] {
    return getMediaKindsFacetFilterItems(this.selectedMediaKinds);
  }

  toggleMediaKindItem = (kind: NoteMediaKind) => {
    const selectedMediaKinds = this.selectedMediaKinds;
    const newSelectedMediaKinds = selectedMediaKinds.includes(kind)
      ? selectedMediaKinds.filter(mediaKind => mediaKind !== kind)
      : [...selectedMediaKinds, kind];

    this.clearMediaKinds();
    this.addFacetFilter({
      kind: "CONTAINS_MEDIA_KIND",
      value: { media_kinds: newSelectedMediaKinds },
    });
  };

  clearMediaKinds = () => {
    this.removeFacetFilter("CONTAINS_MEDIA_KIND");
  };

  // MODIFIED_BY FILTER
  get modifiedByParam(): SearchEngineRequestFacetFilterModifiedBy | undefined {
    const filter = this.params.facetFilters.find(f => f.kind === "MODIFIED_BY_SPACE_ACCOUNT");
    if (filter && "space_account_ids" in filter.value) {
      return filter as SearchEngineRequestFacetFilterModifiedBy;
    }
    return undefined;
  }

  get modifiedByFacetFilterItems(): ContactsFacetFilterItem[] {
    return getContactsFacetFilterItems(
      this.store,
      this.modifiedBySearchQuery,
      this.modifiedByParam?.value.space_account_ids || []
    );
  }

  setModifiedBySearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value || "";
    this.modifiedBySearchQuery = value;
  };

  clearModifiedBySearchQuery = () => {
    this.modifiedBySearchQuery = "";
  };

  toggleModifiedByItem = (id: string) => {
    const selectedContacts = this.modifiedByParam?.value.space_account_ids || [];
    const newSelectedContacts = selectedContacts.includes(id)
      ? selectedContacts.filter(contactId => contactId !== id)
      : [...selectedContacts, id];

    this.clearModifiedBy();
    this.addFacetFilter({
      kind: "MODIFIED_BY_SPACE_ACCOUNT",
      value: { space_account_ids: newSelectedContacts },
    });
  };

  clearModifiedBy = () => {
    this.removeFacetFilter("MODIFIED_BY_SPACE_ACCOUNT");
  };

  // CREATED_BY FILTER
  get createdByParam(): SearchEngineRequestFacetFilterOwnedBy | undefined {
    const filter = this.params.facetFilters.find(f => f.kind === "OWNED_BY_SPACE_ACCOUNT");
    if (filter && "space_account_ids" in filter.value) {
      return filter as SearchEngineRequestFacetFilterOwnedBy;
    }
    return undefined;
  }

  get createdByFacetFilterItems(): ContactsFacetFilterItem[] {
    return getContactsFacetFilterItems(
      this.store,
      this.createdBySearchQuery,
      this.createdByParam?.value.space_account_ids || []
    );
  }

  setCreatedBySearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value || "";
    this.createdBySearchQuery = value;
  };

  clearCreatedBySearchQuery = () => {
    this.createdBySearchQuery = "";
  };

  toggleCreatedByItem = (id: string) => {
    const selectedContacts = this.createdByParam?.value.space_account_ids || [];
    const newSelectedContacts = selectedContacts.includes(id)
      ? selectedContacts.filter(contactId => contactId !== id)
      : [...selectedContacts, id];

    this.clearCreatedBy();
    this.addFacetFilter({
      kind: "OWNED_BY_SPACE_ACCOUNT",
      value: { space_account_ids: newSelectedContacts },
    });
  };

  clearCreatedBy = () => {
    this.removeFacetFilter("OWNED_BY_SPACE_ACCOUNT");
  };
}
