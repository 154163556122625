import { FormattingToolbarContext } from "@/components/note/editor/FormattingToolbar";
import { NoteEditorMetadata } from "@/components/note/editor/NoteEditorMetadata";
import { OutdatedClientNoteNotice } from "@/components/note/OutdatedClientNoteNotice";
import { MemCommonEditorStore } from "@/components/note/store/MemCommonEditorStore";
import { NoteEditorMode } from "@/components/note/types";
import { MdsFloatingDropdownContent } from "@/design-system/components/dropdown/MdsFloatingDropdownContent";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsSpacings } from "@/design-system/foundations/typography";
import { ZIndex } from "@/domains/design/constants";
import { css } from "@/domains/emotion";
import { EmotionClassStyles } from "@/domains/emotion/types";
import { clientEnvModule } from "@/modules/client-env";
import { PublicAppStore, usePublicAppStore } from "@/store";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { IContactModel } from "@/store/contacts/types";
import { INoteObservable } from "@/store/note/types";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import styled from "@emotion/styled";
import {
  MemCommonEditor,
  MemCommonEditorAction,
  MemCommonEditorActionKind,
  MemCommonEditorInstance,
} from "@mem-labs/common-editor";
import { observer } from "mobx-react-lite";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
  MouseEventHandler,
} from "react";
import { useDebounceCallback } from "usehooks-ts";

type NoteObservableEditorProps = EmotionClassStyles & {
  autoFocus?: boolean;
  footer?: React.ReactNode;
  chatHistory?: ChatHistory;
  goToMention?: INotesViewPageStore["goToMention"];
  onMentionMouseOver?: INotesViewPageStore["onMentionMouseOver"];
  onMentionMouseOut?: INotesViewPageStore["onMentionMouseOut"];
  openFileUploadRejectedModal?: INotesViewPageStore["openFileUploadRejectedModal"];
  openImageUploadRejectedModal?: INotesViewPageStore["openImageUploadRejectedModal"];
  noteObservable: INoteObservable;
  myAccount?: IContactModel;
  placeholder?: string;
  showMetadata?: boolean;
  showTopPadding?: boolean;
  mode: NoteEditorMode;
  highlightText?: string;
  onReadOnlyNoteClicked?: () => void;
};

export const NoteObservableEditor = observer<NoteObservableEditorProps>(
  function NoteObservableEditor({
    autoFocus = false,
    className,
    footer,
    chatHistory,
    goToMention,
    openFileUploadRejectedModal,
    openImageUploadRejectedModal,
    noteObservable,
    myAccount,
    placeholder,
    showMetadata = true,
    showTopPadding = true,
    mode,
    highlightText,
    onReadOnlyNoteClicked,
  }) {
    const { publicStore } = usePublicAppStore();
    const editorInstanceRef = useRef<MemCommonEditorInstance | null>(null);
    const dispatchAction = useCallback(
      (event: MemCommonEditorAction) => {
        if (
          mode !== NoteEditorMode.Editable &&
          event.kind !== MemCommonEditorActionKind.ApplyRemoteUpdate
        )
          return;

        return editorInstanceRef.current?.dispatchAction?.(event);
      },
      [mode]
    );

    const readOnly = useMemo(() => mode !== NoteEditorMode.Editable, [mode]);
    const [editorStore, setEditorStore] = useState<MemCommonEditorStore>();
    useHighlighting({ highlightText, editorInstanceRef, animate: !readOnly });

    // Preserve a ref in case state is reset before the component unmounts
    const editorRef = useRef<MemCommonEditorStore>();

    useUnmountEditorStore(editorRef, noteObservable);
    useNote(editorStore, noteObservable, setEditorStore, publicStore);

    // After editorStore is nullified we can reset it
    useEffect(() => {
      if (editorStore) return;

      const newStore = new MemCommonEditorStore({
        autoFocus,
        dispatchAction,
        goToMention,
        openFileUploadRejectedModal,
        openImageUploadRejectedModal,
        noteObservable,
        myAccount,
        placeholder,
        publicStore,
        readOnly,
        chatHistory,
        onReadOnlyNoteClicked,
      });

      editorRef.current = newStore;
      setEditorStore(newStore);

      /**
       * The `MemCommonEditorStore` is tightly coupled to our ui - we need to tie
       * some of its lifecycle methods to our component lifecycle.
       *
       * This will ensure the contents of the editor remain up-to-date (E.g. when
       * receiving remote updates).
       *
       * If `mode` is set to "static", the editor will not have any lifecycle
       * methods registered.
       */

      if (mode === NoteEditorMode.Static) {
        return;
      }

      newStore.mount();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorStore]);

    const focusEditor: React.MouseEventHandler<HTMLDivElement> = useCallback(
      e => {
        if (readOnly) return;
        e.stopPropagation();

        const editor = editorInstanceRef.current?.editor;
        if (!editor?.isEditable || editor?.isFocused) return;

        // This click can only happen above the editor (otherwise the editor would handle it instead)
        // so we focus the editor and put the cursor at the beginning of the document.
        editor.chain().focus().setTextSelection(0).run();
      },
      [readOnly]
    );

    const handleScroll = useDebounceCallback(
      () => {
        dispatchAction({
          kind: MemCommonEditorActionKind.ResendMentionUpdate,
          payload: null,
        });
      },
      20,
      { maxWait: 40 }
    );

    const handleMouseMove: MouseEventHandler<HTMLDivElement> = useDebounceCallback(
      event => {
        editorStore?.setMouseY(event.clientY);
      },
      20,
      { maxWait: 40 }
    );

    if (!editorStore) {
      return <div className={className} translate="no"></div>;
    }

    return (
      <div
        className={className}
        translate="no"
        onScroll={handleScroll}
        onMouseMoveCapture={handleMouseMove}
      >
        {showTopPadding && <TopPadding onClick={focusEditor} />}
        {showMetadata && <NoteEditorMetadata store={editorStore} onClick={focusEditor} />}
        {!editorStore.reloadRequired && (
          <FormattingToolbarContext.Provider value={editorStore}>
            {!editorStore.hasContentError && (
              <MemCommonEditor
                editorInstanceRef={editorInstanceRef}
                editorInitializer={editorStore.editorInitializer}
                editorEventHandler={editorStore.editorEventHandler}
                footer={footer}
              />
            )}
            {editorStore.hasContentError && (
              <OutdatedClientNoteNotice
                noteId={noteObservable.id}
                isDesktop={clientEnvModule.isDesktop()}
              />
            )}
          </FormattingToolbarContext.Provider>
        )}
        <MdsFloatingDropdownContent
          zIndex={ZIndex.MentionsMenu}
          clientRect={editorStore.mentionClientRect}
          placement="bottom-start"
          onHover={editorStore.handleDropdownHover}
          contentListClassName={
            editorStore.isInsertMenuActive
              ? insertMenuContentClassName
              : mentionsListContentClassName
          }
          contentList={editorStore.mentionContentList}
        />
        <MdsFloatingDropdownContent
          zIndex={ZIndex.MentionsMenu}
          clientRect={editorStore.tableMenuClientRect}
          placement={editorStore.tableMenuPlacement}
          offsetOptions={editorStore.tableMenuOffsetOptions}
          contentList={editorStore.tableMenuContentList}
        />
      </div>
    );
  }
);

const TopPadding = styled.div({
  paddingTop: "34px",
});

// Free up store when component unmounts
const useUnmountEditorStore = (
  editorRef: MutableRefObject<MemCommonEditorStore | undefined>,
  noteObservable: INoteObservable
) => {
  useEffect(() => {
    return () => {
      if (editorRef?.current && noteObservable) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        editorRef.current.unmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// Reset editor store to allow TitTap to re-initialize
const useNote = (
  editorStore: MemCommonEditorStore | undefined,
  noteObservable: INoteObservable,
  setEditorStore: (store: MemCommonEditorStore | undefined) => void,
  publicStore: PublicAppStore
) => {
  useEffect(() => {
    if (!editorStore) return;
    if (editorStore.noteObservable?.id === noteObservable.id) return;

    if (noteObservable) {
      editorStore.unmount();
    }

    setEditorStore(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteObservable.id, publicStore]);
};

// Send highlight actions to the editor
interface UseHighlightingArg {
  highlightText: string | undefined;
  editorInstanceRef: MutableRefObject<MemCommonEditorInstance | null>;
  animate: boolean;
}
const useHighlighting = ({ highlightText, editorInstanceRef, animate }: UseHighlightingArg) => {
  // Send a highlight command to the editor when its available, based on the highlight prop
  useEffect(() => {
    // when the user moves from one hover to another without the floating note preview being closed,
    // we need to clear out the previous highlight to avoid some jank
    if (editorInstanceRef.current) {
      editorInstanceRef.current?.dispatchAction?.({
        kind: MemCommonEditorActionKind.HighlightText,
        payload: {
          text: "",
          animate: false,
        },
      });
    }

    // Wait for the editor to be initialized and then highlight the text.
    // NOTE: Even if the instance was available in the above block, we should still use
    // this interval in case the editor is being re-initialized.
    let editorWatcherInterval: ReturnType<typeof setInterval> | null = setInterval(() => {
      if (editorInstanceRef.current?.dispatchAction) {
        if (highlightText) {
          editorInstanceRef.current.dispatchAction({
            kind: MemCommonEditorActionKind.HighlightText,
            payload: { text: highlightText, animate },
          });
        }

        // clear the interval
        if (editorWatcherInterval) {
          clearInterval(editorWatcherInterval);
          editorWatcherInterval = null;
        }
      }
    }, 50);

    return () => {
      // clear the interval
      if (editorWatcherInterval) {
        clearInterval(editorWatcherInterval);
        editorWatcherInterval = null;
      }
    };
  }, [editorInstanceRef, highlightText, animate]);
};

const mentionsListContentClassName = css({
  width: "340px",
  maxWidth: "calc(100% - 20px)",
});

const insertMenuContentClassName = css({
  background: mdsColors().white,
  border: `1px solid ${mdsColors().grey.x25}`,
  borderRadius: mdsSpacings().sm,
  boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  maxWidth: "calc(100% - 20px)",
  padding: "4px",
  width: "288px",
});
