import { MdsButton } from "@/design-system/components/button";
import { mdsSpacings } from "@/design-system/foundations";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";

interface GuidedChatHomePageProps {}

export const GuidedChatHomePage: FC<GuidedChatHomePageProps> = observer(() => {
  const { store } = useAppStore();

  // the chat page should not have an open side panel
  useEffectOnMount(() => {
    store.sidePanel.close();
  });

  const handleNewGuidedConversation = async () => {
    const conversationId = await store.chatConversations.createNewGuidedConversation();
    store.navigation.goToGuidedChatConversation({ conversationId });
  };

  return (
    <div
      style={{
        display: "flex",
        padding: mdsSpacings().md,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MdsButton
        label="New Guided Conversation"
        onClick={() => {
          handleNewGuidedConversation();
        }}
      ></MdsButton>
    </div>
  );
});
