import { IndexedBoolean } from "@/domains/common/types";

export interface SearchSuggestion {
  modelId: string;
  type: SearchSuggestionType;
  label: string;
  lowercaseLabel: string;
  lastViewedAt: string;
  sortKey: number;
  mentionKey?: number;
  isAvailable: IndexedBoolean;
}

export enum SearchSuggestionType {
  NOTE = "NOTE",
  COLLECTION = "COLLECTION",
  OTHER = "OTHER",
}
