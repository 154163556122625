import { StyledSwitch } from "@/mds/components/forms/switch/switch.style";
import { IMDSSwitchProps } from "@/mds/components/forms/switch/switch.types";

export function MDSSwitch(props: IMDSSwitchProps) {
  const { label, style, ...rest } = props;
  return (
    <StyledSwitch {...rest} style={style}>
      <div className="indicator" />
      {label}
    </StyledSwitch>
  );
}
