import { ApiSchema } from "@/modules/api";
import { UpsertedSyncUpdateResponse, DeletedSyncUpdateResponse } from "@/store/sync/types";
import type { AssertTrue, ValidateEnumMatchesType } from "@/domains/typing/types";
import { IndexedBoolean, Maybe } from "@/domains/common/types";

// CHAT CONVERSATION

export type ChatConversationModelData = ApiSchema["ChatConversationSyncModelDataResponseSchema"];
export type ChatConversationKind =
  ApiSchema["CreateChatConversationSyncOperationPayloadV1RequestKindEnum"];

export type ChatConversationUpsertedSyncUpdateValue =
  ApiSchema["ChatConversationSyncModelResponseSchema"];
export type ChatConversationDeletedSyncUpdateValue =
  ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type ChatConversationSyncUpdateValue =
  | ChatConversationUpsertedSyncUpdateValue
  | ChatConversationDeletedSyncUpdateValue;

export type ChatConversationUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  value: ChatConversationUpsertedSyncUpdateValue;
};
export type ChatConversationDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  value: ChatConversationDeletedSyncUpdateValue;
};
export type ChatConversationSyncUpdate =
  | ChatConversationUpsertedSyncUpdate
  | ChatConversationDeletedSyncUpdate;

// CHAT MESSAGE

export type ChatMessageSyncModelStatus = ApiSchema["ChatMessageSyncModelStatus"];
export type ChatMessageSyncModelContextKind = ApiSchema["ChatMessageSyncModelContextKind"];
export type ChatMessageSyncModelSection =
  ApiSchema["ChatMessageSectionSyncModelDataResponseSchema"];
export type ChatMessageSyncModelSource = ApiSchema["ChatMessageSourceSyncModelDataResponseSchema"];

export type ChatMessageSyncModelContext =
  ApiSchema["ChatMessageContextSyncModelDataResponseSchema"];
export type ChatMessageSyncModelContextValue = ChatMessageSyncModelContext["value"];

export type ChatMessageModelData = ApiSchema["ChatMessageSyncModelDataResponseSchema"];
export type ChatMessageUpsertedSyncUpdateValue = ApiSchema["ChatMessageSyncModelResponseSchema"];
export type ChatMessageDeletedSyncUpdateValue = ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type ChatMessageSyncUpdateValue =
  | ChatMessageUpsertedSyncUpdateValue
  | ChatMessageDeletedSyncUpdateValue;

export type ChatMessageUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  kind: "UPSERTED";
  value: ChatMessageUpsertedSyncUpdateValue;
};
export type ChatMessageDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  kind: "DELETED";
  value: ChatMessageDeletedSyncUpdateValue;
};
export type ChatMessageSyncUpdate = ChatMessageUpsertedSyncUpdate | ChatMessageDeletedSyncUpdate;

export type ChatMessageNoteIdContext =
  ApiSchema["ChatMessageContextSyncModelNoteDetailViewChatMessageContextV1Schema"];

export type ChatMessageCollectionIdContext =
  ApiSchema["ChatMessageContextSyncModelCollectionDetailViewChatMessageContextV1Schema"];

// SPACE ACCOUNT CHAT MESSAGE

export type SpaceAccountChatMessageModelData =
  ApiSchema["SpaceAccountChatMessageSyncModelDataResponseSchema"];

export type SpaceAccountChatMessageUpsertedSyncUpdateValue =
  ApiSchema["SpaceAccountChatMessageSyncModelResponseSchema"];
export type SpaceAccountChatMessageDeletedSyncUpdateValue =
  ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type SpaceAccountChatMessageSyncUpdateValue =
  | SpaceAccountChatMessageUpsertedSyncUpdateValue
  | SpaceAccountChatMessageDeletedSyncUpdateValue;

export type SpaceAccountChatMessageUpsertedSyncUpdate = UpsertedSyncUpdateResponse & {
  value: SpaceAccountChatMessageUpsertedSyncUpdateValue;
};
export type SpaceAccountChatMessageDeletedSyncUpdate = DeletedSyncUpdateResponse & {
  value: SpaceAccountChatMessageDeletedSyncUpdateValue;
};
export type SpaceAccountChatMessageSyncUpdate =
  | SpaceAccountChatMessageUpsertedSyncUpdate
  | SpaceAccountChatMessageDeletedSyncUpdate;

export type SpaceAccountChatMessageSection =
  ApiSchema["SpaceAccountChatMessageSyncModelSectionsValueResponseSchema"];

// Chat history:

export enum ChatMessageContextKind {
  Global = "GLOBAL",
  NoteDetailView = "NOTE_DETAIL_VIEW",
  CollectionDetailView = "COLLECTION_DETAIL_VIEW",
  NoteListView = "NOTE_LIST_VIEW",
  CollectionListView = "COLLECTION_LIST_VIEW",
  SearchView = "SEARCH_VIEW",
}

type BackendChatHistoryContextKind = ApiSchema["KindBd4Enum"];
type _ValidateChatHistoryContextKind = AssertTrue<
  ValidateEnumMatchesType<BackendChatHistoryContextKind, ChatMessageContextKind>
>;

export enum ChatItemKind {
  SectionHeader = "SECTION_HEADER",
  Message = "MESSAGE",
}

export interface ChatMessageContext {
  id: string;
  kind: ChatMessageContextKind;
  observable?(): Maybe<{ title?: string }>;
}

export interface ChatSectionHeader {
  title: string;
  // Shared with ChatMessage:
  context: ChatMessageContext;
  locallyCreatedAt: string;
}

export interface ChatItemSectionHeader {
  id: string;
  kind: ChatItemKind.SectionHeader;
  header: ChatSectionHeader;
}

export interface ChatItemMessage {
  id: string;
  kind: ChatItemKind.Message;
}

export type ChatItem = ChatItemSectionHeader | ChatItemMessage;

export type ChatMessageIndexData = {
  locally_created_at: string;
  chat_conversation_id: string;
  status: ChatMessageSyncModelStatus;
  context_ids: string[];
  context_kinds: ChatMessageSyncModelContextKind[];
  is_system_message: IndexedBoolean;
};

export type IndexedChatMessageSyncUpdateValue = ChatMessageUpsertedSyncUpdateValue &
  ChatMessageIndexData;

// TODO: Horrible name, I know. Any suggestions?
export type IndexedChatMessage = [
  chat_conversation_id: string,
  locally_created_at: string,
  is_system_message: boolean,
  status: ChatMessageSyncModelStatus,
  context_ids: string[],
  context_kinds: ChatMessageSyncModelContextKind[],
  model_id: string,
];

export type ChatHistoryIndexes = {
  id: string;
  locallyCreatedAt: string;
  isSystemMessage: boolean;
  status: ChatMessageSyncModelStatus;
  context: ChatMessageContext;
  chatConversationId: string;
};
