import {
  MdsDropdownButtonItem,
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsFloatingDropdownContent } from "@/design-system/components/dropdown/MdsFloatingDropdownContent";
import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIconButton } from "@/design-system/components/icon-button";
import {
  mdsColors,
  mdsFontSizes,
  mdsFontWeights,
  mdsLineHeights,
  mdsSpacings,
} from "@/design-system/foundations";
import { css, cx } from "@/domains/emotion";
import {
  MemCommonRichTextInputInstance,
  MemCommonRichTextInputInitializer,
  MemCommonRichTextInputActionKind,
  MemCommonMentionKeyDownKind,
  MemCommonMentionKind,
  MemCommonRichTextInputEvent,
  MemCommonRichTextInputEventKind,
  MemCommonRichTextInput,
  MemCommonEditorTheme,
  MemCommonCloseMentionCondition,
  MemCommonEditorSlashCommand,
} from "@mem-labs/common-editor";
import { observer } from "mobx-react-lite";
import {
  useRef,
  useMemo,
  useCallback,
  useState,
  useLayoutEffect,
  useEffect,
  MouseEventHandler,
} from "react";
import useMeasure from "react-use/lib/useMeasure";
import styled from "@emotion/styled";
import { ChatMessageContext } from "@/store/chat/types";
import { useDebounceCallback } from "usehooks-ts";
import { useAsync } from "@/modules/use-async";
import { Maybe } from "@/domains/common/types";
import { CommandIds } from "@/store/chat/ChatHistory";
import { ZIndex } from "@/domains/design/constants";

export interface MentionChip {
  id: string;
  className?: MdsDropdownButtonItem["className"];
  iconKind: MdsDropdownButtonItem["iconKind"];
  label: MdsDropdownButtonItem["label"];
  content?: MdsDropdownButtonItem["content"];
  beforeSelection?: () => Promise<void>;
  alwaysVisible?: boolean;
  groupTitle?: string;
  action?: MemCommonEditorSlashCommand | (() => Promise<Maybe<MemCommonEditorSlashCommand>>);
}

export interface ChatInputProps {
  className?: string;
  context?: ChatMessageContext;
  getAvailableChips: (mentionQuery: string) => Promise<MentionChip[]>;
  onHeight?: (height: number) => void;
  onSubmit: (payload: {
    markdownContent: string;
    htmlContent: string;
    isGuidedChat: boolean;
  }) => void;
  inSidePanel?: boolean;
  isGuidedChat_experiment: boolean;
}

export const ChatInput = observer<ChatInputProps>(function RichTextAreaInput({
  className,
  context,
  getAvailableChips,
  onHeight,
  onSubmit,
  isGuidedChat_experiment,
}) {
  const [isEmpty, setIsEmpty] = useState(true);

  const chatInputRef = useRef<MemCommonRichTextInputInstance>(null);
  const chatInputInitializer: MemCommonRichTextInputInitializer = useMemo(() => {
    return async () => ({
      autoFocus: true,
      autoSubmit: true,
      theme: MemCommonEditorTheme.Light,
    });
  }, []);

  const sendSubmitAction = useCallback((event?: MouseEvent) => {
    event?.stopPropagation();
    chatInputRef.current?.dispatchAction({
      kind: MemCommonRichTextInputActionKind.SendContents,
      payload: null,
    });
  }, []);

  const [mentionQuery, setMentionQuery] = useState("");

  const lockedMouseYRef = useRef(0);
  const mouseYRef = useRef(0);
  const handleMouseMove: MouseEventHandler<HTMLDivElement> = useDebounceCallback(
    event => {
      mouseYRef.current = event.clientY;
      if (Math.abs(lockedMouseYRef.current - mouseYRef.current) > 2) {
        // Mouse was moved, unlock hover to select.
        lockedMouseYRef.current = 0;
      }
    },
    20,
    { maxWait: 40 }
  );

  const [keyboardSelectionChipIndex, setKeyboardSelectedChipIndex] = useState(-1);
  const [
    scrollIntoViewMentionKeyboardSelectionChipIndex,
    setScrollIntoViewMentionKeyboardSelectionChipIndex,
  ] = useState(false);

  const { result: availableChips, invoke } = useAsync({
    fn: getAvailableChips,
    options: { defaultValue: [] },
  });

  useEffect(() => {
    invoke(mentionQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionQuery]);

  useEffect(() => {
    // Reset mentions menu.
    setKeyboardSelectedChipIndex(availableChips?.length ? 0 : -1);
    setScrollIntoViewMentionKeyboardSelectionChipIndex(!!availableChips?.length);
    lockedMouseYRef.current = mouseYRef.current;
  }, [availableChips]);

  const availableChipsRef = useRef(availableChips);
  availableChipsRef.current = availableChips;

  const keyboardSelectionChipIndexRef = useRef(keyboardSelectionChipIndex);
  keyboardSelectionChipIndexRef.current = keyboardSelectionChipIndex;

  const handleSelectMention = useCallback(
    async (option?: MentionChip) => {
      const dispatchAction = chatInputRef.current?.dispatchAction;
      if (!dispatchAction || !availableChipsRef.current) return;

      const mention = (() => {
        if (option) return option;

        return availableChipsRef.current[Math.max(keyboardSelectionChipIndexRef.current, 0)];
      })();

      if (!mention) return;

      const action = typeof mention.action === "function" ? await mention.action() : mention.action;
      if (!action && typeof mention.action === "function") return;

      mention.beforeSelection?.();

      dispatchAction({
        kind: MemCommonRichTextInputActionKind.CloseMention,
        payload: {
          id: mention.id,
          kind: mentionQuery.startsWith("#")
            ? MemCommonMentionKind.Collection
            : // if it starts w/ "/" it's safe to return Note here because no mention is inserted.
              MemCommonMentionKind.Note,
          label: mention.label,
          action,
        },
      });
    },
    [mentionQuery]
  );

  const contentList = useMemo<MdsDropdownContentList>(() => {
    if (!availableChips) return { items: [] };

    const scrollableItems: MdsDropdownItem[] = [];
    const scrollable: MdsDropdownItem = {
      id: "scrollable",
      kind: MdsDropdownItemKind.ScrollableSection,
      items: scrollableItems,
      className: scrollableMentionStyles,
    };
    const contentList: MdsDropdownContentList = {
      items: [],
    };
    const unsupportedCommandIds: string[] = [CommandIds.InsertFile, CommandIds.InsertImage];
    let lastGroupTitle = "";
    availableChips.forEach((mention, index) => {
      if (unsupportedCommandIds.includes(mention.id)) return;

      let items = scrollableItems;
      if (mention.alwaysVisible && scrollableItems.length) {
        items = contentList.items;
        items.push({
          id: mention.id + "-separator",
          kind: MdsDropdownItemKind.Divider,
        });
      }
      const { groupTitle } = mention;
      if (groupTitle && groupTitle !== lastGroupTitle) {
        items.push({
          id: `group-${groupTitle}`,
          kind: MdsDropdownItemKind.Detail,
          text: groupTitle,
          className: groupTitleStyles,
        });
        lastGroupTitle = groupTitle;
      }
      items.push({
        id: mention.id,
        kind: MdsDropdownItemKind.Button,
        iconKind: mention.iconKind,
        content: mention.content,
        label: mention.label,
        scrollIntoView:
          scrollIntoViewMentionKeyboardSelectionChipIndex && index === keyboardSelectionChipIndex,
        className: cx(
          mention.className,
          index === keyboardSelectionChipIndex ? selectedOptionStyles : noHoverStyles
        ),
        onClick: () => {
          handleSelectMention(mention);
        },
      });
    });

    const mentionContent = mentionQuery.slice(1).trim().toLowerCase();

    if (scrollableItems.length && !mentionQuery.startsWith("/")) {
      const taggingCollection = mentionQuery.startsWith("#");
      const mentionTypeName = taggingCollection ? "collection" : "note";
      const text = `${taggingCollection ? "Tag" : "Mention"} a${!mentionContent.length ? " recent" : ""} ${mentionTypeName}`;
      scrollableItems.unshift({
        id: "header",
        kind: MdsDropdownItemKind.Detail,
        className: detailPaddingStyles,
        text,
      });
    }
    if (scrollableItems.length) {
      contentList.items.unshift(scrollable);
    }
    return contentList;
  }, [
    availableChips,
    handleSelectMention,
    keyboardSelectionChipIndex,
    mentionQuery,
    scrollIntoViewMentionKeyboardSelectionChipIndex,
  ]);

  useEffect(() => {
    const dispatchAction = chatInputRef.current?.dispatchAction;
    if (!dispatchAction || !mentionQuery) return;

    dispatchAction({
      kind: MemCommonRichTextInputActionKind.CloseMention,
      payload: {
        condition: contentList.items.length
          ? MemCommonCloseMentionCondition.RenderingResults
          : MemCommonCloseMentionCondition.NoResults,
      },
    });
  }, [contentList, mentionQuery]);

  const handleDropdownHover = useCallback(({ itemId }: { itemId?: string }) => {
    // Lock hover to select until mouse is moved enough.
    // Since it's assigned to mouseenter/leave, this only takes effect when a new element is hovered.
    if (lockedMouseYRef.current) return;

    setKeyboardSelectedChipIndex(availableChipsRef.current?.findIndex(e => e.id === itemId) ?? -1);
    setScrollIntoViewMentionKeyboardSelectionChipIndex(false);
  }, []);

  const [ref, { height }] = useMeasure<HTMLDivElement>();

  useLayoutEffect(() => {
    onHeight?.(height + 2 * CHAT_INPUT_PADDING);
  }, [height, onHeight]);

  const [mentionClientRect, setMentionClientRect] = useState<DOMRect>();

  const chatInputEventHandler = useCallback(
    (event: MemCommonRichTextInputEvent) => {
      console.debug(`[ChatInput] Event ${JSON.stringify(event)}`, undefined, 2);
      switch (event.kind) {
        case MemCommonRichTextInputEventKind.Contents: {
          onSubmit({ ...event.payload, isGuidedChat: isGuidedChat_experiment });
          break;
        }
        case MemCommonRichTextInputEventKind.MentionUpdateQuery: {
          setMentionQuery(event.payload.mentionQuery);
          setMentionClientRect(event.payload.clientRect ?? undefined);
          break;
        }
        case MemCommonRichTextInputEventKind.MentionKeyDown: {
          const availableChips = availableChipsRef.current;
          if (!availableChips) break;

          switch (event.payload.kind) {
            case MemCommonMentionKeyDownKind.ArrowDown: {
              lockedMouseYRef.current = mouseYRef.current;
              setKeyboardSelectedChipIndex(index => {
                if (index < 0) return 0;
                return (index + 1 + availableChips.length) % availableChips.length;
              });
              setScrollIntoViewMentionKeyboardSelectionChipIndex(true);
              break;
            }
            case MemCommonMentionKeyDownKind.ArrowUp: {
              lockedMouseYRef.current = mouseYRef.current;
              setKeyboardSelectedChipIndex(index => {
                if (index < 0) return availableChips.length - 1;
                return (index - 1 + availableChips.length) % availableChips.length;
              });
              setScrollIntoViewMentionKeyboardSelectionChipIndex(true);
              break;
            }
            case MemCommonMentionKeyDownKind.Enter: {
              if (availableChips.length) {
                handleSelectMention();
              }
              break;
            }
          }
          break;
        }
        case MemCommonRichTextInputEventKind.IsEmpty: {
          setIsEmpty(event.payload.isEmpty);
          break;
        }
      }
    },
    [handleSelectMention, isGuidedChat_experiment, onSubmit]
  );

  return (
    <Container className={className} ref={ref} onMouseMoveCapture={handleMouseMove}>
      <Expand>
        <Scrollable>
          <MemCommonRichTextInput
            richTextInputEventHandler={chatInputEventHandler}
            richTextInputInitializer={chatInputInitializer}
            richTextInputInstanceRef={chatInputRef}
            key={context?.id}
          >
            <MdsFloatingDropdownContent
              zIndex={ZIndex.MentionsMenu}
              clientRect={mentionClientRect}
              placement="top-start"
              onHover={handleDropdownHover}
              contentListClassName={
                mentionQuery.startsWith("/")
                  ? insertMenuContentClassName
                  : mentionsListContentClassName
              }
              contentList={contentList}
            />
          </MemCommonRichTextInput>
        </Scrollable>
      </Expand>
      <svg width="0" height="0" className="hidden">
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="7.03%" stopColor="#EB2487" />
          <stop offset="93.99%" stopColor="#F93939" />
        </linearGradient>
      </svg>
      <BottomAligner>
        <MdsIconButton
          isDisabled={isEmpty}
          iconStyles={isEmpty ? undefined : sendIconStyles}
          iconKind={isEmpty ? MdsIconKind.SendAlt : MdsIconKind.Send}
          onClick={sendSubmitAction}
        />
      </BottomAligner>
    </Container>
  );
});

const sendIconStyles = css({
  "> path": {
    fill: `url(#gradient)`,
  },
});

const CHAT_INPUT_PADDING = 12;

const mentionsListContentClassName = css({
  maxHeight: "500px",
  width: "340px",
  maxWidth: "calc(100% - 20px)",
});

const insertMenuContentClassName = css({
  background: mdsColors().white,
  border: `1px solid ${mdsColors().grey.x25}`,
  borderRadius: mdsSpacings().sm,
  boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  maxWidth: "calc(100% - 20px)",
  padding: "4px",
  width: "288px",
});

const Container = styled.div({
  borderRadius: "8px",
  border: `1px solid #F3F4F6`,
  boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  padding: `${CHAT_INPUT_PADDING}px 6px ${CHAT_INPUT_PADDING}px 0`,
  width: "100%",
});

const Expand = styled.div({
  flex: 1,
});

const Scrollable = styled.div(({ theme }) => ({
  flex: 1,
  height: "fit-content",
  maxHeight: `calc(6 * ${theme.lineHeights.medium})`,
  overflowY: "auto",

  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const selectedOptionStyles = css({
  background: mdsColors().grey.x25,
});

const noHoverStyles = css({
  "&:hover": {
    background: "unset",
  },
});

const BottomAligner = styled.div({
  alignItems: "flex-end",
  display: "flex",
  height: "100%",
  width: "fit-content",
});

const scrollableMentionStyles = css({
  maxHeight: 220,
});

const detailPaddingStyles = css({
  padding: "8px 0 4px 10px",
});

const groupTitleStyles = css({
  color: mdsColors().grey.x500,
  fontSize: mdsFontSizes().xxsmall,
  fontWeight: mdsFontWeights().semiBold,
  lineHeight: mdsLineHeights().xsmall,
  padding: `${mdsSpacings().sm} ${mdsSpacings().sm} ${mdsSpacings().xs} ${mdsSpacings().sm} `,
});
