import { action, computed, makeObservable } from "mobx";
import { AppSubStoreArgs } from "@/store/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppCalloutKind, SpaceAccountAppCalloutModelData } from "@/store/app-callout/types";
import { SpaceAccountAppCalloutObservable } from "@/store/app-callout/SpaceAccountAppCalloutObservable";
import { sortBy } from "lodash-es";
import { DismissSpaceAccountAppCalloutOperation } from "@/store/sync/operations/app-callout/DismissSpaceAccountAppCalloutOperation";
import { DateTime } from "luxon";

export class AppStoreSpaceAccountAppCalloutsStore extends BaseSyncModelStore<
  SpaceAccountAppCalloutObservable,
  SpaceAccountAppCalloutModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountAppCallout, ...injectedDeps });

    makeObservable(this, {
      createSyncModel: false,
      appCalloutBanners: computed,
      highestPriorityVisibleBanner: computed,
      dismissAppCallout: action,
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountAppCalloutModelData>
  ): SpaceAccountAppCalloutObservable {
    return new SpaceAccountAppCalloutObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  get appCalloutBanners(): SpaceAccountAppCalloutObservable[] {
    return this.all.filter(appCallout => appCallout.calloutKind === AppCalloutKind.BANNER);
  }

  /**
   * For now, the highest-priority app banner is just the one with the
   * earliest created-at date.
   */
  get highestPriorityVisibleBanner(): SpaceAccountAppCalloutObservable | null {
    const visibleBanners = this.appCalloutBanners.filter(appCallout => appCallout.isVisible);

    return sortBy(visibleBanners, "createdAt")?.[0] ?? null;
  }

  /**
   * Actions
   */

  async dismissAppCallout({ appCallout }: { appCallout: SpaceAccountAppCalloutObservable }) {
    await new DismissSpaceAccountAppCalloutOperation({
      store: this.store,
      payload: {
        space_account_app_callout_id: appCallout.id,
        dismissed_at: DateTime.now().toISO(),
      },
    }).execute();
  }
}
