import EmptyCollections from "@/assets/images/empty-collections.svg";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { EmptyContainer, EmptyText } from "@/components/styling/empty";
import { LensKind } from "@/modules/lenses/types";
import { NotesListPageEmptyButton } from "@/pages/notes/components/NotesListPageEmptyButton";

const COPY: Record<LensKind, string> = {
  [LensKind.All]:
    "You haven't created any notes. To import your notes from Mem 1.0, click 'Import from Mem 1.0' from your profile dropdown.",
  [LensKind.AddedByMe]:
    "You haven't created any notes. To import your notes from Mem 1.0, click 'Import from Mem 1.0' from your profile dropdown.",
  [LensKind.SharedWithMe]: "No notes have been shared with you",
} as const;

const COPY_WITH_FILTERS = "No results match your current filters";

interface NotesListPageListEmptyProps {
  area: LensKind;
}

export const NotesListPageListEmpty = observer<NotesListPageListEmptyProps>(({ area }) => {
  const { pageStore } = useAppStore();
  const page = pageStore.notesListPage;
  const { filters } = page;
  const copy = filters.hasAnyFilters ? COPY_WITH_FILTERS : COPY[area];

  return (
    <EmptyContainer dataTestId="notes-list-page-list-empty">
      <img src={EmptyCollections} alt={copy} />
      <EmptyText>{copy}</EmptyText>
      <NotesListPageEmptyButton area={area} />
    </EmptyContainer>
  );
});
