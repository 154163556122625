import { MdsIconKind } from "@/design-system/components/icon";
import { PlatformIconKind } from "@/domains/api/types";
import downloadLaptopMobileImage from "@/assets/images/download-laptop-mobile.svg";

type PlatformIconData =
  | { icon: MdsIconKind; image?: undefined }
  | { image: string; icon?: undefined };

const iconKindMapping: Record<PlatformIconKind, PlatformIconData> = {
  [PlatformIconKind.DownloadLaptopMobile]: { image: downloadLaptopMobileImage },
  [PlatformIconKind.Laptop]: { icon: MdsIconKind.Laptop },
  [PlatformIconKind.Mobile]: { icon: MdsIconKind.Mobile },
};

export const resolvePlatformIconData = ({
  iconKind,
}: {
  iconKind: PlatformIconKind;
}): PlatformIconData => {
  return iconKindMapping[iconKind];
};
