import { uuidModule } from "@/modules/uuid";
import {
  SpaceAccountAppCalloutModelData,
  SpaceAccountAppCalloutUpsertedSyncUpdateValue,
} from "@/store/app-callout/types";
import { BaseSyncOperation } from "@/store/sync/operations/BaseSyncOperation";
import { generateDefaultOwnerScopes } from "@/store/sync/operations/helpers/common";
import { IDismissSpaceAccountAppCalloutOperation } from "@/store/sync/operations/types";
import { SyncModelData, SyncOperationKind, OptimisticSyncUpdate } from "@/store/sync/types";

export class DismissSpaceAccountAppCalloutOperation extends BaseSyncOperation<IDismissSpaceAccountAppCalloutOperation> {
  get operationKind(): SyncOperationKind {
    return "DISMISS_SPACE_ACCOUNT_APP_CALLOUT";
  }

  generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[] {
    const optimisticUpdates: OptimisticSyncUpdate<SyncModelData>[] = [];
    const spaceAccountAppCalloutUpdate = this.generateDismissSpaceAccountAppCalloutUpdate();

    if (spaceAccountAppCalloutUpdate) {
      optimisticUpdates.push(spaceAccountAppCalloutUpdate);
    }

    return optimisticUpdates;
  }

  async triggerRecompute() {
    await this.store.spaceAccountAppCallouts.recompute(this.payload.space_account_app_callout_id);
  }

  protected generateDismissSpaceAccountAppCalloutUpdate(): OptimisticSyncUpdate<SpaceAccountAppCalloutModelData> {
    const observable = this.store.spaceAccountAppCallouts.get(
      this.payload.space_account_app_callout_id
    );

    const value: SpaceAccountAppCalloutUpsertedSyncUpdateValue = {
      model_id: this.payload.space_account_app_callout_id,
      model_kind: "SPACE_ACCOUNT_APP_CALLOUT",
      model_version: observable?.modelVersion ?? 0,
      model_data: {
        callout_kind: observable?.calloutKind ?? "UNKNOWN",
        is_dismissible: observable?.isDismissible ?? false,
        is_active: observable?.isActive ?? false,
        start_at: observable?.startAt?.toISO() ?? null,
        end_at: observable?.endAt?.toISO() ?? null,
        target_platform_kinds: observable?.targetPlatformKinds ?? [],
        icon_kind: observable?.iconKind ?? null,
        primary_label: observable?.primaryLabel ?? "",
        secondary_label: observable?.secondaryLabel ?? "",
        inline_action_label: observable?.inlineActionLabel ?? null,
        inline_action_url: observable?.inlineActionUrl ?? null,
        primary_action_label: observable?.primaryActionLabel ?? null,
        primary_action_url: observable?.primaryActionUrl ?? null,
        primary_action_icon_kind: observable?.primaryActionIconKind ?? null,
        secondary_action_label: observable?.secondaryActionLabel ?? null,
        secondary_action_url: observable?.secondaryActionUrl ?? null,
        secondary_action_icon_kind: observable?.secondaryActionIconKind ?? null,
        space_account_id: observable?.spaceAccountId ?? "",
        /**
         * This is flipped to true.
         */
        is_dismissed: true,
      },
      model_scopes: [generateDefaultOwnerScopes({ store: this.store })],
    };

    const syncUpdate: OptimisticSyncUpdate<SpaceAccountAppCalloutModelData> = {
      optimistic_update_id: uuidModule.generate(),
      locally_committed_at: this.committedAt,
      kind: "UPSERTED",
      value,
    };

    return syncUpdate;
  }
}
