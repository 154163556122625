import { makeObservable, observable, runInAction, action, computed } from "mobx";
import { api } from "@/modules/api";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { AsyncResult } from "@/modules/async-result/types";
import { asyncResultModule } from "@/modules/async-result";
import { BaseError } from "@/domains/errors";
import { objectModule } from "@/modules/object";
import { AccountTransitionInfoObservable } from "@/store/account-transition/observables/AccountTransitionInfoObservable";
import { AccountTransitionContentInfoObservable } from "@/store/account-transition/observables/AccountTransitionContentInfoObservable";
import { DateTime } from "luxon";
import { MINIMUM_IMPORT_DURATION_SECONDS } from "@/store/account-transition/constants";

/**
 * Used for tracking information related to the Mem 1.0 -> Mem 2.0 transition.
 */
export class AppStoreAccountTransitionStore extends AppSubStore {
  fetchTransitionInfoState: AsyncResult<AccountTransitionInfoObservable>;
  fetchTransitionContentState: AsyncResult<AccountTransitionContentInfoObservable>;
  startFreshProcessState: AsyncResult<{ startedAt: DateTime }>;
  startImportProcessState: AsyncResult<{ startedAt: DateTime }>;
  importProcessStartedModalIsOpen: boolean;

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.fetchTransitionInfoState = asyncResultModule.setInitial();
    this.fetchTransitionContentState = asyncResultModule.setInitial();
    this.startFreshProcessState = asyncResultModule.setInitial();
    this.startImportProcessState = asyncResultModule.setInitial();
    this.importProcessStartedModalIsOpen = false;

    makeObservable<AppStoreAccountTransitionStore>(this, {
      startFreshProcessState: observable,
      startImportProcessState: observable,
      fetchTransitionInfoState: observable,
      fetchTransitionContentState: observable,
      importProcessStartedModalIsOpen: observable,
      startFreshProcess: action,
      startImportProcess: action,
      fetchTransitionInfo: action,
      fetchTransitionContent: action,
      resetState: action,
      closeImportProcessStartedModal: action,
      transitionProcessIsError: computed,
      transitionProcessIsLoading: computed,
      userNeedsToCompleteV2Transition: computed,
      importProcessStartedWithinLastMinute: computed,
    });
  }

  async fetchTransitionInfo(): Promise<AccountTransitionInfoObservable | BaseError> {
    runInAction(() => {
      this.fetchTransitionInfoState = asyncResultModule.setLoading();
    });

    const result = await api.get("/v2/me/v2-migration/account-transition-info", {});

    if (result.data) {
      const accountTransitionInfoData = result.data;

      const accountTransitionInfo = new AccountTransitionInfoObservable({
        forceUpgradedToVersionTwo: accountTransitionInfoData.force_upgraded_to_version_two,
        hasMatchingVersionTwoAccount: accountTransitionInfoData.has_matching_version_two_account,
        versionTwoTransitionDecisionRequired:
          accountTransitionInfoData.version_two_transition_decision_required,
      });

      runInAction(() => {
        this.fetchTransitionInfoState = asyncResultModule.setReady(accountTransitionInfo);
      });

      /**
       * We only fetch the "Transition Content" if we need it.
       */
      if (this.userNeedsToCompleteV2Transition) {
        await this.fetchTransitionContent();
      }

      return accountTransitionInfo;
    } else {
      const err = new BaseError({
        message:
          "[AppStoreAccountTransitionStore.fetchTransitionInfo] Failed to fetch current account.",
        info: {
          result: objectModule.safeAsJson(result),
        },
      });

      runInAction(() => {
        this.fetchTransitionInfoState = asyncResultModule.setError(err);
      });

      return err;
    }
  }

  get userNeedsToCompleteV2Transition(): boolean {
    /**
     * Once we've selected one of the options, we've completed the transition.
     */
    if (this.startFreshProcessState.data || this.startImportProcessState.data) {
      return false;
    }

    if (!this.fetchTransitionInfoState.data) {
      return false;
    }

    if (this.fetchTransitionInfoState.data.versionTwoTransitionDecisionRequired) {
      return true;
    }

    return false;
  }

  async fetchTransitionContent(): Promise<AccountTransitionContentInfoObservable | BaseError> {
    runInAction(() => {
      this.fetchTransitionContentState = asyncResultModule.setLoading();
    });

    const result = await api.get("/v2/me/v2-migration/v1-account-content-info", {});

    if (result.data) {
      const resultData = result.data;

      const accountTransitionContentInfo = new AccountTransitionContentInfoObservable({
        noteIds: resultData.note_ids,
        collectionIds: resultData.collection_ids,
      });

      runInAction(() => {
        this.fetchTransitionContentState = asyncResultModule.setReady(accountTransitionContentInfo);
      });

      return accountTransitionContentInfo;
    } else {
      const err = new BaseError({
        message:
          "[AppStoreAccountTransitionStore.fetchTransitionContent] Failed to fetch current account.",
        info: {
          result: objectModule.safeAsJson(result),
        },
      });

      runInAction(() => {
        this.fetchTransitionContentState = asyncResultModule.setError(err);
      });

      return err;
    }
  }

  async startFreshProcess(): Promise<{ startedAt: DateTime } | BaseError> {
    runInAction(() => {
      this.startFreshProcessState = asyncResultModule.setLoading();
    });

    const result = await api.post("/v2/me/v2-migration/start-fresh", {});

    if (result.data) {
      const startFreshProcessData = {
        startedAt: DateTime.now(),
      };

      runInAction(() => {
        this.startFreshProcessState = asyncResultModule.setReady(startFreshProcessData);
      });

      return startFreshProcessData;
    } else {
      const err = new BaseError({
        message:
          "[AppStoreAccountTransitionStore.startFreshProcess] Failed to start transition process.",
        info: {
          result: objectModule.safeAsJson(result),
        },
      });

      runInAction(() => {
        this.startFreshProcessState = asyncResultModule.setError(err);
      });

      return err;
    }
  }

  async startImportProcess(): Promise<{ startedAt: DateTime } | BaseError> {
    runInAction(() => {
      this.startImportProcessState = asyncResultModule.setLoading();
    });

    const result = await api.post("/v2/me/v2-migration/start-import-from-v1", {});

    if (result.data) {
      const startImportProcessData = {
        startedAt: DateTime.now(),
      };
      runInAction(() => {
        this.startImportProcessState = asyncResultModule.setReady(startImportProcessData);
        this.importProcessStartedModalIsOpen = true;
      });

      return startImportProcessData;
    } else {
      const err = new BaseError({
        message:
          "[AppStoreAccountTransitionStore.startImportProcess] Failed to start transition process.",
        info: {
          result: objectModule.safeAsJson(result),
        },
      });

      runInAction(() => {
        this.startImportProcessState = asyncResultModule.setError(err);
      });

      return err;
    }
  }

  get importProcessStartedWithinLastMinute(): boolean {
    /**
     * We use this to flag some of the UI elements on/off.
     * The 60 seconds is important here - because this data is managed "outside" of the sync system
     * (via API calls) - but it does use some sync models [DATA_IMPORT] - we want to ensure at least
     * 60s has passed (because this is when we trigger polling-based syncs.)
     */
    if (!this.startImportProcessState.data) {
      return false;
    }

    return (
      Math.abs(this.startImportProcessState.data.startedAt.diffNow().as("seconds")) <
      MINIMUM_IMPORT_DURATION_SECONDS
    );
  }

  get transitionProcessIsLoading(): boolean {
    return this.startFreshProcessState.loading || this.startImportProcessState.loading;
  }

  get transitionProcessIsError(): boolean {
    return !!this.startFreshProcessState.error || !!this.startImportProcessState.error;
  }

  closeImportProcessStartedModal(): void {
    runInAction(() => {
      this.importProcessStartedModalIsOpen = false;
    });
  }

  resetState(): void {
    runInAction(() => {
      this.fetchTransitionInfoState = asyncResultModule.setInitial();
      this.fetchTransitionContentState = asyncResultModule.setInitial();
      this.startFreshProcessState = asyncResultModule.setInitial();
      this.startImportProcessState = asyncResultModule.setInitial();
      this.importProcessStartedModalIsOpen = false;
    });
  }
}
