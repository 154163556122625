import { NoteObservableEditor } from "@/components/note/NoteObservableEditor";
import {
  NOTE_EDITOR_TOP_BAR_HEIGHT,
  NoteEditorTopBar,
} from "@/components/note/editor/top-bar/NoteEditorTopBar";
import { NoteEditorMode } from "@/components/note/types";
import { NoteSkeleton } from "@/components/skeleton/NoteSkeleton";
import { MdsButton, MdsButtonVariant } from "@/design-system/components/button";
import { mdsMediaQueries } from "@/design-system/foundations";
import { css } from "@/domains/emotion";
import { trackEvent, TrackedEvent } from "@/domains/metrics";
import { logger } from "@/modules/logger";
import { forceReloadWindow } from "@/modules/window/reload";
import { ChatHistory } from "@/store/chat/ChatHistory";
import { IContactModel } from "@/store/contacts/types";
import { INoteObservable } from "@/store/note/types";
import { INotesViewPageStore } from "@/store/pages/NotesViewPageStore/types";
import { useAppStore } from "@/store/utils/hooks";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

interface NoteEditorProps {
  isSidePanelOpen: boolean;
  chatHistory?: ChatHistory;
  goToMention?: INotesViewPageStore["goToMention"];
  openFileUploadRejectedModal?: INotesViewPageStore["openFileUploadRejectedModal"];
  openImageUploadRejectedModal?: INotesViewPageStore["openImageUploadRejectedModal"];
  noteObservable?: INoteObservable;
  myAccount?: IContactModel;
  inSidePanel?: boolean;
  highlightText?: string;
  autoFocus?: boolean;
}

export const NoteEditor = observer<NoteEditorProps>(function NoteEditor({
  chatHistory,
  goToMention,
  openFileUploadRejectedModal,
  openImageUploadRejectedModal,
  noteObservable,
  myAccount,
  inSidePanel,
  highlightText,
  autoFocus,
}) {
  const showSkeleton = noteObservable && !noteObservable.isNoteContentDocumentLoaded;
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const { store } = useAppStore();
  useTitle(noteObservable);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (showSkeleton) {
      timeoutId = setTimeout(() => {
        logger.warn({
          message: "[NoteEditor][showRefreshButton] Time elapsed, showing refresh button",
          info: {
            noteId: noteObservable?.id,
            noteTitle: noteObservable?.title,
          },
        });

        setShowRefreshButton(true);
      }, 5000);
    } else {
      setShowRefreshButton(false);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSkeleton]);

  /**
   * Track views of the note editor.
   */
  useEffect(() => {
    if (!noteObservable) return;
    store.notes.setNewNoteIsBeingCreated(false);

    trackEvent(TrackedEvent.NoteView, {
      note_id: noteObservable.id,
      note_primary_label: noteObservable.title,
      is_in_side_panel: inSidePanel,
    });
  }, [inSidePanel, noteObservable, store]);

  if (!noteObservable) return null;
  return (
    <div className={fullHeight}>
      <NoteEditorTopBar note={noteObservable} inSidePanel={inSidePanel} />
      {showSkeleton && (
        <>
          <NoteSkeleton />
          {showRefreshButton && (
            <RefreshButtonWrapper>
              <MdsButton
                label="This note is taking longer than expected to load. Refresh?"
                variant={MdsButtonVariant.Filled}
                onClick={() => forceReloadWindow()}
              />
            </RefreshButtonWrapper>
          )}
        </>
      )}
      {!showSkeleton && noteObservable && (
        <NoteObservableEditor
          autoFocus={autoFocus}
          className={editorStyles}
          chatHistory={chatHistory}
          goToMention={goToMention}
          openFileUploadRejectedModal={openFileUploadRejectedModal}
          openImageUploadRejectedModal={openImageUploadRejectedModal}
          noteObservable={noteObservable}
          myAccount={myAccount}
          mode={NoteEditorMode.Editable}
          highlightText={highlightText}
        />
      )}
    </div>
  );
});

const fullHeight = css({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "relative",
  width: "100%",
});

// Note: left padding is always 16px larger than right padding due to the scrollbar.
// Only issue is on mobile where the scrollbar is overlay it's not part of the width calculations.
const editorStyles = css({
  "--extra-padding-left": `56px`,
  "--extra-padding-right": `56px`,
  background: `linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .08), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .08), rgba(0, 0, 0, 0)) 0 100%`,
  backgroundRepeat: "no-repeat",
  backgroundColor: "#fff",
  backgroundSize: "100% 30px, 100% 30px, 100% 12px, 100% 12px",
  backgroundAttachment: "local, local, scroll, scroll",
  boxSizing: "border-box",
  cursor: "text",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: `calc(100% - ${NOTE_EDITOR_TOP_BAR_HEIGHT})`,
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  // Consistent sizing whether the scrollbar is visible or not.
  scrollbarGutter: "stable",

  "--extra-padding-bottom": "200px",

  [mdsMediaQueries().rules.tablet]: {
    "--extra-padding-left": `40px`,
    "--extra-padding-right": `40px`,
    "--extra-padding-bottom": "100px",
  },

  [mdsMediaQueries().rules.phone]: {
    "--extra-padding-left": `16px`,
    "--extra-padding-right": `16px`,
    "--extra-padding-bottom": "100px",
  },
});

/** Preserve the default document title */
const defaultTitle = document.title;

/** Prefix document title with Note title */
const useTitle = (note: INoteObservable | undefined): void => {
  document.title = note ? `${note?.title} - Mem` : defaultTitle;
  useEffect(() => {
    return () => {
      document.title = defaultTitle;
    };
  }, []);
};

const RefreshButtonWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
});
