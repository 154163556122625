const mdsBaseColors = () => ({
  grey: {
    x0: "#FFFFFF",
    x25: "#F8F8FA",
    x50: "#F3F3F5",
    x100: "#E8E9ED",
    x200: "#D3D5DA",
    x300: "#CACCD3",
    x400: "#9397A5",
    x500: "#696E7D",
    x600: "#3F424D",
    x700: "#323640",
    x800: "#1A1C23",
    x875: "#111827",
    x900: "#111318",
  },
  primary: {
    x25: "#FFFCFD",
    x50: "#FFCEDF",
    x100: "#FBBECE",
    x200: "#F7809F",
    x300: "#F55780",
    x400: "#F04773",
    x500: "#F22E61",
    x600: "#CF2652",
    x700: "#AB1D42",
    x800: "#76112B",
    x900: "#53081C",
  },
  secondary: {
    x100: "#EDF2FF",
    x200: "#9DB6FA",
    x300: "#6B8FF2",
    x400: "#3B6DF1",
    x500: "#3360D6",
    x600: "#234191",
    x700: "#152759",
  },
  green: {
    x400: "#09A57F",
    x700: "#6BC9B2",
  },
  blue: {
    x100: "#EDF2FF",
    x200: "#9DB6FA",
    x300: "#6B8FF2",
    x400: "#3B6DF1",
    x500: "#3360D6",
    x600: "#234191",
    x700: "#152759",
  },
  mblue: {
    x50: "#F0F4FF",
    x100: "#DCE6FE",
    x200: "#BECFFE",
    x300: "#91AEFD",
    x400: "#618BFA",
    x500: "#3C6FF6",
    x600: "#2458EB",
    x700: "#1D4FD7",
    x800: "#1E44AE",
    x900: "#1E3B8A",
  },
  red: {
    x50: "#FEF2F2",
    x100: "#FEE2E2",
    x200: "#FECACA",
    x300: "#FCA5A5",
    x400: "#F87171",
    x500: "#EF4444",
    x600: "#DC2626",
    x700: "#B91C1C",
    x800: "#991B1B",
    x900: "#7F1D1D",
  },
  lilac: {
    pale: "#F5EDF9",
    paler: "#F3E9F8",
    x100: "#AA52D6",
    x200: "#AB5BD2",
    x300: "#903EB8",
  },
  white: "#FFF",
  selectedBackground: "#F5F8FF",
  transparent: "transparent",
  chat: {
    header: "#6B7280",
  },
});

export const mdsColors = () => {
  const baseColors = mdsBaseColors();

  return {
    ...baseColors,
  } as const;
};
