import { AppStore } from "@/store";
import { CollectionSearchReturnType } from "@/store/collections/types";
import {
  computed,
  makeObservable,
  observable,
  action,
  reaction,
  runInAction,
  onBecomeObserved,
} from "mobx";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { CollectionToggleOption, NotesListPageParams } from "@/modules/filters/notes/types";
import { LensKind, SortByKind } from "@/modules/lenses/types";
import { NotesLensKind, CollectionsFilterMode, NotesSortByKind, NoteMediaKind } from "@/store/note";
import { MdsDropdownItemKind } from "@/design-system/components/dropdown";
import { lensModule } from "@/modules/lenses";
import { MdsDropdownContentList } from "@/design-system/components/dropdown";
import { DateFacetFilterItem, RangeValue } from "@/components/filters/DateFacetFilter/types";
import { ContactsFacetFilterItem } from "@/components/filters/types";
import { UNTITLED_COLLECTION_TITLE } from "@/domains/untitled/untitled";
import { Key } from "react";
import { logger } from "@/modules/logger";
import { CollectionsFacetFilterItem } from "@/components/filters/CollectionsFacetFilter/types";
import { CalendarDate } from "@internationalized/date";
import { Maybe } from "@/domains/common/types";
import { MediaKindsFacetFilterItem } from "@/components/filters/MediaKindsFacetFilter/types";
import { getMediaKindsFacetFilterItems } from "@/modules/filters/common/getMediaKindsFacetFilterItems";
import { getContactsFacetFilterItems } from "@/modules/filters/common/getContactsFacetFilterItems";
import { getSelectedCollectionTitles } from "@/modules/filters/common/getSelectedCollectionTitles";

export class NotesListFilter {
  public static readonly DEFAULT_SORT_BY = SortByKind.LastModified;
  public static readonly DEFAULT_LENS = LensKind.All;
  public static readonly DEFAULT_LIMIT = 100;

  public static dateFilterMap: Record<
    NotesSortByKind,
    "createdAt" | "modifiedAt" | "viewedAt" | "sharedWithMeAt"
  > = {
    [SortByKind.LastCreated]: "createdAt",
    [SortByKind.LastModified]: "modifiedAt",
    [SortByKind.LastViewed]: "viewedAt",
    [SortByKind.Alphabetical]: "createdAt",
    [SortByKind.LastShared]: "sharedWithMeAt",
  };

  params: NotesListPageParams;
  loadingCollections: boolean = false;
  createdBySearchQuery: string = "";
  modifiedBySearchQuery: string = "";
  addedBySearchQuery: string = "";
  collectionsSearchQuery: string = "";
  searchedAndLoadedCollections: CollectionObservable[] = [];
  excludeCollections: Maybe<CollectionObservable>[] = [];

  constructor(
    private readonly store: AppStore,
    private readonly supportedSortOptions: SortByKind[]
  ) {
    this.params = {
      sortBy: NotesListFilter.DEFAULT_SORT_BY,
      lens: NotesListFilter.DEFAULT_LENS,
      limit: NotesListFilter.DEFAULT_LIMIT,
    };

    makeObservable<
      this,
      | "dateFilterMap"
      | "getNewSelectedContacts"
      | "getNewSelectedCollections"
      | "getNewSelectedMediaKinds"
      | "getNewSelectedCollectionsMode"
    >(this, {
      initializeCollectionsSearch: true,

      // PARAMS
      params: observable,
      setParams: action,
      setLens: action,
      setSortBy: action,
      sortOptions: computed,
      increaseLimit: action,
      lens: computed,
      sortBy: computed,
      sortLabel: computed,
      setFilter: action,
      clearAllFilters: action,
      hasAnyFilters: computed,
      dateFilterMap: false,
      dateFilterItems: computed,
      clearDateFilter: action,
      dateSelectedRange: computed,

      createdBySearchItems: computed,
      createdBySearchQuery: observable,
      toggleCreatedByItem: action,
      setCreatedBySearchQuery: action,
      clearCreatedBy: action,
      clearCreatedBySearchQuery: action,

      getNewSelectedContacts: true,

      modifiedBySearchQuery: observable,
      modifiedBySearchItems: computed,
      setModifiedBySearchQuery: action,
      clearModifiedBySearchQuery: action,
      toggleModifiedByItem: action,
      clearModifiedBy: action,

      getNewSelectedCollections: true,

      searchedAndLoadedCollections: observable,
      collectionsSearchQuery: observable,
      collectionsFilterItems: computed,
      setCollectionSearchQuery: action,
      clearCollectionSearchQuery: action,
      toggleCollectionItem: action,
      clearCollections: action,
      loadingCollections: observable,
      setCollectionsFilterMode: action,
      collectionsMode: computed,
      selectedCollectionModeKey: computed,
      setCollectionNoCollectionIsSelected: action,
      onCollectionModeSelectionChange: action,
      collectionNoCollectionIsSelected: computed,
      getNewSelectedCollectionsMode: true,
      selectedCollectionTitles: computed,
      excludeCollections: observable,
      setExcludeCollection: action,

      addedBySearchQuery: observable,
      addedBySearchItems: computed,
      setAddedBySearchQuery: action,
      clearAddedBySearchQuery: action,
      toggleAddedByItem: action,
      clearAddedBy: action,

      // MEDIA KIND FILTER
      mediaKindsFacetFilterItems: computed,
      selectedMediaKinds: computed,
      toggleMediaKindItem: action,
      clearMediaKinds: action,
      getNewSelectedMediaKinds: true,
    });

    onBecomeObserved(this, "collectionsFilterItems", () => this.initializeCollectionsSearch());
  }

  public setParams(params: Partial<NotesListPageParams>) {
    const lens = params.lens ?? this.params.lens;
    const sortBy = params.sortBy ?? this.params.sortBy;
    const limit = params.limit ?? this.params.limit;
    const filter = params.filter ?? this.params.filter;

    this.params = { lens, sortBy, limit, filter };
  }

  initializeCollectionsSearch() {
    reaction(
      () => [this.collectionsSearchQuery, this.excludeCollections],
      async () => {
        runInAction(() => {
          this.loadingCollections = true;
        });

        const collectionIndexTuples = await this.store.collections.search({
          query: this.collectionsSearchQuery,
          limit: 10000,
          sortBy: SortByKind.LastViewed,
          excludeIds: this.excludeCollections
            .map(collection => collection?.id)
            .filter(Boolean) as string[],
          // we don't collect CollectionObservable because we want a control over loading state (aka wait for getAsync and then then loader off)
          // it also solves the problem of initial render
          returns: CollectionSearchReturnType.CollectionIndexTuple,
        });

        const collections = await Promise.all(
          collectionIndexTuples.map(tuple => this.store.collections.getAsync(tuple[2]))
        ).then(results => results.filter(collection => !!collection));

        runInAction(() => {
          this.searchedAndLoadedCollections = collections;
          this.loadingCollections = false;
        });
      },
      { fireImmediately: true }
    );
  }

  setLens(lens: NotesLensKind) {
    this.setParams({
      lens,
      sortBy: NotesListFilter.DEFAULT_SORT_BY,
      limit: NotesListFilter.DEFAULT_LIMIT,
    });
  }

  setSortBy = ({ itemId }: { itemId: string }) => {
    this.setParams({ sortBy: itemId as NotesSortByKind, limit: NotesListFilter.DEFAULT_LIMIT });
  };

  setFilter = (filter: NotesListPageParams["filter"]) => {
    this.setParams({ ...this.params.filter, filter, limit: NotesListFilter.DEFAULT_LIMIT });
  };

  increaseLimit = () => {
    this.setParams({ limit: this.params.limit + NotesListFilter.DEFAULT_LIMIT });
  };

  get lens() {
    return this.params.lens;
  }

  get sortBy(): SortByKind {
    return this.params.sortBy;
  }

  get sortLabel(): string {
    return lensModule.sortKindLabelMap[this.sortBy];
  }

  get sortOptions(): MdsDropdownContentList {
    const items: MdsDropdownContentList["items"] = [
      {
        id: "sort-by-divider",
        kind: MdsDropdownItemKind.Detail,
        text: "Sort by",
      },
    ];

    for (const sortOption of this.supportedSortOptions) {
      switch (sortOption) {
        case SortByKind.LastCreated:
          items.push({
            id: SortByKind.LastCreated,
            kind: MdsDropdownItemKind.Button,
            label: lensModule.sortKindLabelMap[SortByKind.LastCreated],
            isChecked: this.sortBy === SortByKind.LastCreated,
            onClick: this.setSortBy,
          });
          break;

        case SortByKind.LastModified:
          items.push({
            id: SortByKind.LastModified,
            kind: MdsDropdownItemKind.Button,
            label: lensModule.sortKindLabelMap[SortByKind.LastModified],
            isChecked: this.sortBy === SortByKind.LastModified,
            onClick: this.setSortBy,
          });
          break;

        case SortByKind.LastViewed:
          items.push({
            id: SortByKind.LastViewed,
            kind: MdsDropdownItemKind.Button,
            label: lensModule.sortKindLabelMap[SortByKind.LastViewed],
            isChecked: this.sortBy === SortByKind.LastViewed,
            onClick: this.setSortBy,
          });
          break;

        case SortByKind.Alphabetical:
          items.push({
            id: SortByKind.Alphabetical,
            kind: MdsDropdownItemKind.Button,
            label: lensModule.sortKindLabelMap[SortByKind.Alphabetical],
            isChecked: this.sortBy === SortByKind.Alphabetical,
            onClick: this.setSortBy,
          });
          break;

        case SortByKind.LastShared:
          if (this.lens === LensKind.SharedWithMe) {
            items.push({
              id: SortByKind.LastShared,
              kind: MdsDropdownItemKind.Button,
              label: lensModule.sortKindLabelMap[SortByKind.LastShared],
              isChecked: this.sortBy === SortByKind.LastShared,
              onClick: this.setSortBy,
            });
          }
          break;

        case SortByKind.LastAdded:
          items.push({
            id: SortByKind.LastAdded,
            kind: MdsDropdownItemKind.Button,
            label: lensModule.sortKindLabelMap[SortByKind.LastAdded],
            isChecked: this.sortBy === SortByKind.LastAdded,
            onClick: this.setSortBy,
          });
          break;
      }
    }

    return { items };
  }

  get hasAnyFilters() {
    if (!this.params.filter) {
      return false;
    }

    if (this.params.filter.createdBy) {
      return true;
    }

    if (this.params.filter.modifiedBy) {
      return true;
    }

    if (this.params.filter.addedBy) {
      return true;
    }

    if (this.params.filter.collections) {
      return true;
    }

    if (this.params.filter.date) {
      return true;
    }

    if (this.params.filter.mediaKinds) {
      return true;
    }

    return false;
  }

  get dateFilterItems(): DateFacetFilterItem[] {
    return [
      {
        type: "any",
        title: "Any date",
        isSelected:
          !this.params.filter?.date?.olderThan &&
          !this.params.filter?.date?.from &&
          !this.params.filter?.date?.to,
        onSelect: () => this.clearDateFilter(),
      },
      {
        type: "older-than",
        title: "Older than a week",
        isSelected: this.params.filter?.date?.olderThan === "week",
        onSelect: () =>
          this.setFilter({
            ...this.params.filter,
            date: { olderThan: "week", from: undefined, to: undefined },
          }),
      },
      {
        type: "older-than",
        title: "Older than a month",
        isSelected: this.params.filter?.date?.olderThan === "month",
        onSelect: () =>
          this.setFilter({
            ...this.params.filter,
            date: { olderThan: "month", from: undefined, to: undefined },
          }),
      },
      {
        type: "older-than",
        title: "Older than a year",
        isSelected: this.params.filter?.date?.olderThan === "year",
        onSelect: () =>
          this.setFilter({
            ...this.params.filter,
            date: { olderThan: "year", from: undefined, to: undefined },
          }),
      },
      {
        type: "custom-range",
        title: "Custom date range",
        isSelected: !!this.params.filter?.date?.from,
        onSelect: ({ from, to }) => {
          this.setFilter({
            ...this.params.filter,
            date: {
              from: from?.toString(),
              to: to?.toString(),
              olderThan: undefined,
            },
          });
        },
      },
    ];
  }

  get modifiedBySearchItems(): ContactsFacetFilterItem[] {
    return getContactsFacetFilterItems(
      this.store,
      this.modifiedBySearchQuery,
      this.params.filter?.modifiedBy || []
    );
  }

  get createdBySearchItems(): ContactsFacetFilterItem[] {
    return getContactsFacetFilterItems(
      this.store,
      this.createdBySearchQuery,
      this.params.filter?.createdBy || []
    );
  }

  get addedBySearchItems(): ContactsFacetFilterItem[] {
    return getContactsFacetFilterItems(
      this.store,
      this.addedBySearchQuery,
      this.params.filter?.addedBy || []
    );
  }

  get mediaKindsFacetFilterItems(): MediaKindsFacetFilterItem[] {
    return getMediaKindsFacetFilterItems(this.selectedMediaKinds);
  }

  get collectionsMode(): CollectionsFilterMode {
    return this.params.filter?.collections?.mode || "anyOrNone";
  }

  get selectedCollectionModeKey(): CollectionToggleOption {
    const mode = this.params.filter?.collections?.mode;
    if (mode === "all") {
      return "in-all-of";
    }

    return "in-any-of";
  }

  setExcludeCollection = (collections: Maybe<CollectionObservable>[]) => {
    this.excludeCollections = collections;
  };

  setCreatedBySearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value || "";
    this.createdBySearchQuery = value;
  };

  setModifiedBySearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value || "";
    this.modifiedBySearchQuery = value;
  };

  setAddedBySearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value || "";
    this.addedBySearchQuery = value;
  };

  setCollectionSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value || "";
    this.collectionsSearchQuery = value;
  };

  setCollectionsFilterMode = (mode: CollectionsFilterMode, noCollectionIsSelected?: boolean) => {
    this.setFilter({
      ...this.params.filter,
      noCollectionIsSelected:
        noCollectionIsSelected ?? this.params.filter?.noCollectionIsSelected ?? false,
      collections: {
        ...this.params.filter?.collections,
        ids: this.params.filter?.collections?.ids || [],
        mode,
      },
    });
  };

  get collectionNoCollectionIsSelected() {
    return this.params.filter?.noCollectionIsSelected || false;
  }

  setCollectionNoCollectionIsSelected = (isSelected: boolean) => {
    if (!isSelected) {
      this.setCollectionsFilterMode("any", isSelected);
      return;
    }

    if (!this.params.filter?.collections || this.params.filter?.collections?.ids.length === 0) {
      this.setCollectionsFilterMode("none", isSelected);
      return;
    }

    this.setCollectionsFilterMode("anyOrNone", isSelected);
  };

  onCollectionModeSelectionChange = (keys: Set<Key>) => {
    const firstKey = keys.values().next().value as CollectionToggleOption;
    if (firstKey === "in-all-of") {
      this.setCollectionsFilterMode("all");
      return;
    }

    if (firstKey !== "in-any-of") {
      logger.error({
        message: "Unsupported key selected",
        info: { firstKey },
      });

      return;
    }

    if (this.params.filter?.noCollectionIsSelected) {
      this.setCollectionsFilterMode("anyOrNone");
      return;
    }

    this.setCollectionsFilterMode("any");
  };

  clearCreatedBySearchQuery = () => {
    this.createdBySearchQuery = "";
  };

  clearModifiedBySearchQuery = () => {
    this.modifiedBySearchQuery = "";
  };

  clearCollectionSearchQuery = () => {
    this.collectionsSearchQuery = "";
  };

  clearAddedBySearchQuery = () => {
    this.addedBySearchQuery = "";
  };

  toggleCreatedByItem = (id: string) => {
    const newSelectedContacts = this.getNewSelectedContacts(id, "createdBy");

    this.setFilter({
      ...this.params.filter,
      createdBy: newSelectedContacts,
    });
  };

  toggleModifiedByItem = (id: string) => {
    const newSelectedContacts = this.getNewSelectedContacts(id, "modifiedBy");

    this.setFilter({
      ...this.params.filter,
      modifiedBy: newSelectedContacts,
    });
  };

  toggleAddedByItem = (id: string) => {
    const newSelectedContacts = this.getNewSelectedContacts(id, "addedBy");

    this.setFilter({
      ...this.params.filter,
      addedBy: newSelectedContacts,
    });
  };

  toggleCollectionItem = (id: string) => {
    const newSelectedCollections = this.getNewSelectedCollections(id);

    this.setFilter({
      ...this.params.filter,
      collections: {
        ...this.params.filter?.collections,
        ids: newSelectedCollections,
        mode: this.getNewSelectedCollectionsMode(newSelectedCollections),
      },
    });
  };

  toggleMediaKindItem = (id: NoteMediaKind) => {
    const newSelectedMediaKinds = this.getNewSelectedMediaKinds(id);
    this.setFilter({
      ...this.params.filter,
      mediaKinds: newSelectedMediaKinds,
    });
  };

  get selectedCollectionTitles(): string[] {
    return getSelectedCollectionTitles(
      this.store,
      this.params.filter?.collections?.ids || [],
      this.params.filter?.collections?.mode,
      this.params.filter?.noCollectionIsSelected
    );
  }

  get selectedMediaKinds(): NoteMediaKind[] {
    return this.params.filter?.mediaKinds || [];
  }

  private getNewSelectedContacts = (id: string, field: "createdBy" | "modifiedBy" | "addedBy") => {
    const selectedContacts = this.params.filter?.[field] || [];
    const newSelectedContacts = selectedContacts.includes(id)
      ? selectedContacts.filter(contactId => contactId !== id)
      : [...selectedContacts, id];

    return newSelectedContacts;
  };

  private getNewSelectedMediaKinds = (id: NoteMediaKind): NoteMediaKind[] => {
    const selectedMediaKinds = this.params.filter?.mediaKinds || [];
    const newSelectedMediaKinds = selectedMediaKinds.includes(id)
      ? selectedMediaKinds.filter(mediaKind => mediaKind !== id)
      : [...selectedMediaKinds, id];

    return newSelectedMediaKinds;
  };

  private getNewSelectedCollections = (id: string) => {
    const selectedCollections = this.params.filter?.collections?.ids || [];
    const newSelectedCollections = selectedCollections.includes(id)
      ? selectedCollections.filter(collectionId => collectionId !== id)
      : [...selectedCollections, id];

    return newSelectedCollections;
  };

  private getNewSelectedCollectionsMode = (
    selectedCollections: string[]
  ): CollectionsFilterMode => {
    if (this.params.filter?.collections?.mode === "all") {
      return "all";
    }

    if (this.params.filter?.noCollectionIsSelected && selectedCollections.length > 0) {
      return "anyOrNone";
    }

    if (this.params.filter?.noCollectionIsSelected && selectedCollections.length === 0) {
      return "none";
    }

    return "any";
  };

  clearCreatedBy = () => {
    this.setFilter({
      ...this.params.filter,
      createdBy: undefined,
    });
  };

  clearModifiedBy = () => {
    this.setFilter({
      ...this.params.filter,
      modifiedBy: undefined,
    });
  };

  clearAddedBy = () => {
    this.setFilter({
      ...this.params.filter,
      addedBy: undefined,
    });
  };

  clearCollections = () => {
    this.setFilter({
      ...this.params.filter,
      collections: undefined,
      noCollectionIsSelected: false,
    });
  };

  clearMediaKinds = () => {
    this.setFilter({
      ...this.params.filter,
      mediaKinds: undefined,
    });
  };

  get dateSelectedRange(): RangeValue | undefined {
    if (!this.params.filter?.date?.from || !this.params.filter?.date?.to) {
      return undefined;
    }

    const splitFrom = this.params.filter.date.from.split("-");
    const from = new CalendarDate(Number(splitFrom[0]), Number(splitFrom[1]), Number(splitFrom[2]));

    const splitTo = this.params.filter.date.to.split("-");
    const to = new CalendarDate(Number(splitTo[0]), Number(splitTo[1]), Number(splitTo[2]));

    return {
      start: from,
      end: to,
    };
  }

  get collectionsFilterItems(): CollectionsFacetFilterItem[] {
    return this.searchedAndLoadedCollections.map(collection => ({
      id: collection.id,
      title: collection.title || UNTITLED_COLLECTION_TITLE,
      subtitle: collection.description,
      isSelected: this.params.filter?.collections?.ids.includes(collection.id),
      isVisible: true,
    }));
  }

  clearDateFilter = () => {
    this.setFilter({
      ...this.params.filter,
      date: undefined,
    });
  };

  clearAllFilters = () => {
    this.clearCreatedBy();
    this.clearModifiedBy();
    this.clearAddedBy();
    this.clearCollections();
    this.clearDateFilter();
    this.clearMediaKinds();
  };
}
