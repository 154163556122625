import { MdsIconKind } from "@/design-system/components/icon";
import { MdsIcon } from "@/design-system/components/icon";
import { mdsColors } from "@/design-system/foundations";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { css } from "@/domains/emotion";
import styled from "@emotion/styled";
import { useAppStore } from "@/store";

export const DeepSearchHeaderText = observer(() => {
  const { pageStore } = useAppStore();
  const page = pageStore.searchPage;

  return (
    <AnimatePresence>
      {page.showDeepLoader && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ y: 20, opacity: 0 }}
          transition={{
            type: "spring",
            bounce: 0.6,
            duration: 1,
          }}
        >
          <ActionsWrapper>
            <MdsIcon
              kind={MdsIconKind.Radar}
              innerStyles={{ Icon: { className: deepSearchStyles } }}
            />
            <ActionsText>Searching deep across your notes and collections</ActionsText>
          </ActionsWrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

const deepSearchStyles = css({
  color: mdsColors().lilac.x200,
});

const ActionsWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 8,
});

const ActionsText = styled.div(({ theme }) => ({
  color: theme.colors.grey.x500,
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.small,
}));
