import { ChatView } from "@/components/chat/ChatView";
import { useEffectOnMount } from "@/domains/react/useEffectOnMount";
import { uuidModule } from "@/modules/uuid";
import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

interface GuidedChatConversationPageProps {}

export const GuidedChatConversationPage: FC<GuidedChatConversationPageProps> = observer(() => {
  const { store } = useAppStore();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { conversationId } = useParams<{ conversationId?: string }>();
  const conversationUuid = uuidModule.maybeParseValue(conversationId);

  const subscribeToChat = useCallback(() => {
    if (conversationId && !isSubscribed) {
      store.chatConversations.subscribeToLiveChatConversation(conversationId);
      setIsSubscribed(true);
    }
  }, [conversationId, isSubscribed, store.chatConversations]);

  const unsubscribeFromChat = useCallback(() => {
    if (conversationId) {
      store.chatConversations.unsubscribeFromLiveChatConversation(conversationId);
      setIsSubscribed(false);
    }
  }, [conversationId, store.chatConversations]);

  // the chat page should not have an open side panel
  useEffectOnMount(() => {
    store.sidePanel.close();

    return () => {
      unsubscribeFromChat();
    };
  });

  if (!conversationUuid) {
    // TODO: post-experiment, make a better "not found" page for Chat Conversations
    return <div>Conversation not found</div>;
  }

  return (
    <ChatView
      chatHistory={store.chatMessages.getChatHistoryById_forGuidedChat(conversationUuid)}
      isGuidedChat_experiment
      subscribeToChat={subscribeToChat}
    />
  );
});
