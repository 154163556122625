import { generateKeyBetween } from "fractional-indexing";
import {
  computed,
  makeObservable,
  action,
  onBecomeObserved,
  override,
  observable,
  runInAction,
  onBecomeUnobserved,
} from "mobx";
import { DraggableLocation } from "@hello-pangea/dnd";
import { FavoriteItemObservable } from "@/store/favorite-items/FavoriteItemObservable";
import { FavoriteItemModelData } from "@/store/favorite-items/types";
import { AppSubStoreArgs } from "@/store/types";
import { UpdateFavoriteItemSortKeyOperation } from "@/store/sync/operations/favorites/UpdateFavoriteItemSortKeyOperation";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { OptimisticSyncUpdate, SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { FavoriteItemIndexes } from "@/store/favorite-items/FavoriteItemIndexes";
import { Maybe } from "@/domains/common/types";
import { Dexie, liveQuery, Subscription } from "dexie";
import { AppStore } from "@/store/AppStore";

type IndexType = [sort_key: string, model_id: string];

export class AppStoreFavoriteItemsStore extends BaseSyncModelStore<
  FavoriteItemObservable,
  FavoriteItemModelData
> {
  liveQuerySubscription: Maybe<Subscription>;
  sortedFavoriteItemIds: IndexType[] = [];

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.FavoriteItem, ...injectedDeps });
    makeObservable(this, {
      computeIndexes: override,
      createSyncModel: false,

      sortedFavoriteItemIds: observable,
      liveQuerySubscription: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      sortedFavoriteItems: computed,
      reorderFavoriteItems: action,
    });

    onBecomeObserved(this, "sortedFavoriteItemIds", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "sortedFavoriteItemIds", () => this.unsubscribeLiveQuery());
  }

  subscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(() =>
      this.localTable
        .where("[sort_key+model_id]")
        .between([Dexie.minKey, Dexie.maxKey], [Dexie.maxKey, Dexie.maxKey])
        .keys()
    ).subscribe({
      next: ids => {
        runInAction(() => {
          this.sortedFavoriteItemIds = ids as unknown as IndexType[];
        });
      },
    });
  }

  unsubscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
  }

  createSyncModel(updateValue: SyncUpdateValue<FavoriteItemModelData>): FavoriteItemObservable {
    return new FavoriteItemObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });
  }

  get sortedFavoriteItems(): FavoriteItemObservable[] {
    return this.sortedFavoriteItemIds
      .map(([_sortKey, modelId]) => this.get(modelId))
      .filter(e => !!e)
      .filter(e => e?.item?.isAvailable);
  }

  reorderFavoriteItems = async (destination: DraggableLocation, source: DraggableLocation) => {
    if (destination.index > this.sortedFavoriteItems.length) return;

    const sortOrder = (() => {
      if (destination.index > source.index) {
        // Key between the destination and the next item.
        const start = this.sortedFavoriteItems[destination.index].sortKey;
        const end = this.sortedFavoriteItems[destination.index + 1]?.sortKey ?? null;
        return generateKeyBetween(start, end);
      } else if (destination.index < source.index) {
        // Key between the destination and the previous item.
        const end = this.sortedFavoriteItems[destination.index].sortKey;
        const start = this.sortedFavoriteItems[destination.index - 1]?.sortKey ?? null;
        return generateKeyBetween(start, end);
      }
    })();

    const favoriteItem = this.sortedFavoriteItems[source.index];
    if (!favoriteItem || !sortOrder) return;

    await new UpdateFavoriteItemSortKeyOperation({
      store: this.store,
      payload: {
        favorite_item_id: favoriteItem.id,
        sort_key: sortOrder,
      },
    }).execute();
  };

  public computeIndexes({
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<FavoriteItemModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<FavoriteItemModelData>[];
  }): Record<string, unknown> {
    return new FavoriteItemIndexes({ remoteData, optimisticUpdates }).indexes;
  }
}
