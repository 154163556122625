import { makeObservable, override } from "mobx";
import { SpaceAccountNoteObservable } from "@/store/recent-items/SpaceAccountNoteObservable";
import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountNoteModelData } from "@/store/recent-items/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { Uuid } from "@/domains/global/identifiers";

export class AppStoreSpaceAccountNotesStore extends BaseSyncModelStore<
  SpaceAccountNoteObservable,
  SpaceAccountNoteModelData
> {
  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountNote, ...injectedDeps });
    makeObservable<this>(this, {
      recompute: override,
      createSyncModel: false,
    });
  }

  createSyncModel(data: SyncUpdateValue<SpaceAccountNoteModelData>): SpaceAccountNoteObservable {
    return new SpaceAccountNoteObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  public async recompute(modelId: Uuid) {
    await super.recompute(modelId);
    const data = await this.localTable.get(modelId);
    const noteId = data?.model_data.note_id;
    await this.updateObservable(modelId);
    if (noteId) await this.store.notes.recompute(noteId);
  }
}
