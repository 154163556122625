import { Optional } from "@/domains/common/types";
import { Uuid } from "@/domains/global/identifiers";
import { clientEnvModule } from "@/modules/client-env";
import { logger } from "@/modules/logger";
import { GuestAppStore } from "@/store";
import { AppStore } from "@/store/AppStore";
import { BaseSyncOperationGeneric } from "@/store/sync/operations/BaseSyncOperationGeneric";
import {
  GuestModeSupportedSyncOperationKind,
  ISyncOperation,
  ISyncOperationGuestMode,
} from "@/store/sync/operations/types";
import { OptimisticSyncUpdate, SyncModelData } from "@/store/sync/types";
import { getLoggableOperation } from "@/store/sync/utils";

export interface BaseSyncOperationGuestModeParams<
  T extends ISyncOperation | ISyncOperationGuestMode = ISyncOperation | ISyncOperationGuestMode,
> {
  store: AppStore | GuestAppStore;
  payload: Optional<T["payload"], "schema_version">;
  operationId?: Uuid;
  committedAt?: string;
  latestSpaceAccountSequenceId?: number;
}

export abstract class BaseSyncOperationGuestMode<
  SyncOperation extends ISyncOperation,
  SyncOperationGuestMode extends ISyncOperationGuestMode,
> extends BaseSyncOperationGeneric<SyncOperation> {
  abstract get operationKind(): GuestModeSupportedSyncOperationKind;

  constructor({
    store,
    payload,
    operationId,
    committedAt,
    latestSpaceAccountSequenceId,
  }: BaseSyncOperationGuestModeParams<SyncOperation>) {
    super({ store, payload, operationId, committedAt, latestSpaceAccountSequenceId });
  }

  public async generateSyncOperation(): Promise<SyncOperation> {
    return {
      id: this.operationId,
      client_id: await clientEnvModule.clientId(),
      locally_committed_at: this.committedAt,
      operation_kind: this.operationKind,
      payload: this.payload,
    } as SyncOperation;
  }

  public async generateSyncOperationGuestMode(): Promise<SyncOperationGuestMode> {
    return {
      id: this.operationId,
      client_id: await clientEnvModule.clientId(),
      locally_committed_at: this.committedAt,
      operation_kind: this.operationKind,
      payload: this.payload,
    } as SyncOperationGuestMode;
  }

  public generateOptimisticUpdates(): OptimisticSyncUpdate<SyncModelData>[] {
    return [];
  }

  public handleCustomError(_errorInfo: Record<string, unknown>) {}

  public async execute(): Promise<void> {
    const syncOp = await this.generateSyncOperation();
    if (!syncOp) {
      logger.error({
        message: "can't generate syncOp",
        info: {
          operation: getLoggableOperation(this),
        },
      });

      return;
    }

    await this.store.sync.actionQueue.push(this);
    await this.applyOptimisticUpdates(syncOp.id);
    await this.triggerRecompute();
  }

  protected async applyOptimisticUpdates(syncOpId: Uuid) {
    const optimisticUpdates = this.generateOptimisticUpdates();
    for (const optimisticUpdate of optimisticUpdates) {
      await this.store.sync.actionQueue.applyOptimisticUpdate(syncOpId, optimisticUpdate);
    }
  }
}
