import { Uuid } from "@/domains/global/identifiers";
import { ApiClient } from "@/modules/api";
import { logger } from "@/modules/logger";
import {
  ApiQueryFetchDataHandler,
  ApiQueryNextPageAvailabilityChecker,
} from "@/store/queries/types";
import { PaginatableApiQueryObservable } from "@/store/queries/common/PaginatableApiQueryObservable";
import {
  SearchEngineParams,
  SearchEngineRequest,
  SearchEngineResponse,
  SearchEngineResult,
} from "@/modules/url-params/search-engine-params/types";

export enum SearchEngineKind {
  FULL = "FULL",
  FAST = "FAST",
  SMART = "SMART",
}

const DEFAULT_SEARCH_ENGINE_PAGE_SIZE = 10;
const DEFAULT_FAST_SEARCH_ENGINE_PAGE_SIZE = 40;
const DEFAULT_SMART_SEARCH_ENGINE_PAGE_SIZE = 40;

const searchEngineKindToEndpoint = {
  [SearchEngineKind.FULL]: "/v2/search",
  [SearchEngineKind.FAST]: "/v2/fast_search",
  [SearchEngineKind.SMART]: "/v2/smart_search",
} as const;

export const createSearchEngineApiQueryObservable = ({
  getSpaceId,
  searchParams: { queryString, filters, facetFilters, sortBy },
  apiClient,
  searchEngineKind,
}: {
  getSpaceId: () => Uuid;
  searchParams: SearchEngineParams;
  apiClient: ApiClient;
  searchEngineKind: SearchEngineKind;
}): PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult> => {
  const isEmptyQuery = !queryString && !filters.length && !facetFilters.length;

  const handleFetchPageData: ApiQueryFetchDataHandler<SearchEngineResponse> = async ({
    latestRequest,
  }) => {
    if (isEmptyQuery) {
      logger.debug({
        message: "[generateSearchEngineQueryObservable] Skipping - Empty Query.",
      });

      return;
    }

    const searchSessionCursor = latestRequest?.pagination_info?.search_session_cursor ?? undefined;

    if (latestRequest && !searchSessionCursor) {
      logger.debug({
        message: "[generateSearchEngineQueryObservable] Skipping - No More Pages.",
      });

      return;
    }

    const body: SearchEngineRequest = {
      space_id: getSpaceId(),
      pagination_info: {
        sort_by: sortBy,
        page_size:
          searchEngineKind === SearchEngineKind.FAST
            ? DEFAULT_FAST_SEARCH_ENGINE_PAGE_SIZE
            : searchEngineKind === SearchEngineKind.SMART
              ? DEFAULT_SMART_SEARCH_ENGINE_PAGE_SIZE
              : DEFAULT_SEARCH_ENGINE_PAGE_SIZE,
      },
      query: queryString,
      filters: filters,
      facet_filters: facetFilters,
      config: {
        supported_section_kinds: ["HEADING", "ITEM", "CAROUSEL"],
      },
    };

    const response = await apiClient.post(searchEngineKindToEndpoint[searchEngineKind], {
      params: {
        query: {
          space_id: body.space_id,
          search_session_cursor: searchSessionCursor,
        },
      },
      body,
    });

    if (response.error) {
      throw new Error(`[handleFetchData] Failed. response=${response.error}`);
    }

    return response.data;
  };

  const checkNextPageAvailability: ApiQueryNextPageAvailabilityChecker<SearchEngineResponse> = ({
    latestRequest,
  }) => {
    if (!latestRequest) {
      return true;
    }

    return Boolean(latestRequest.pagination_info.search_session_cursor);
  };

  return new PaginatableApiQueryObservable<SearchEngineResponse, SearchEngineResult>({
    handleFetchPageData,
    checkNextPageAvailability,
    mapPageDataToItems: async ({ data }) => data.results,
    config: {
      retries: 1,
    },
  });
};
