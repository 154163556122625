import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";
import { AppCalloutBannerContent } from "@/components/banners/app-callout-banner/app-callout-banner-content";
import { trackEvent } from "@/domains/metrics";
import { TrackedEvent } from "@/domains/metrics";

interface AppCalloutBannerProps {}

export const AppCalloutBanner: FC<AppCalloutBannerProps> = observer(() => {
  const { store } = useAppStore();

  const appCallout = store.spaceAccountAppCallouts.highestPriorityVisibleBanner;

  if (!appCallout) {
    return null;
  }

  const handleDismiss = useCallback(async () => {
    trackEvent(TrackedEvent.AppCalloutBannerDismissed, {
      url: appCallout.primaryActionUrl,
    });

    await store.spaceAccountAppCallouts.dismissAppCallout({ appCallout });
  }, [appCallout, store.spaceAccountAppCallouts]);

  return (
    <AppCalloutBannerContent
      iconKind={appCallout.iconKind}
      isDismissible={appCallout.isDismissible}
      primaryLabel={appCallout.primaryLabel}
      secondaryLabel={appCallout.secondaryLabel}
      inlineActionLabel={appCallout.inlineActionLabel}
      inlineActionUrl={appCallout.inlineActionUrl}
      primaryActionLabel={appCallout.primaryActionLabel}
      primaryActionUrl={appCallout.primaryActionUrl}
      primaryActionIconKind={appCallout.primaryActionIconKind}
      secondaryActionLabel={appCallout.secondaryActionLabel}
      secondaryActionUrl={appCallout.secondaryActionUrl}
      secondaryActionIconKind={appCallout.secondaryActionIconKind}
      handleDismiss={handleDismiss}
    />
  );
});
