import { Uuid } from "@/domains/global/identifiers";
import { EventContext } from "@/domains/metrics/context";
import { AppStore } from "@/store";
import { CollectionItemObservable } from "@/store/collection-items/CollectionItemObservable";
import { CollectionItemKind } from "@/store/collection-items/types";
import { INoteObservable } from "@/store/note";
import { AddNoteToCollectionOperation } from "@/store/sync/operations/collections/AddNoteToCollectionOperation";
import { RemoveNoteFromCollectionOperation } from "@/store/sync/operations/collections/RemoveNoteFromCollectionOperation";
import { WithAppStore } from "@/store/types";
import { Dexie, liveQuery, Subscription } from "dexie";
import {
  makeObservable,
  computed,
  action,
  observable,
  runInAction,
  onBecomeObserved,
  onBecomeUnobserved,
} from "mobx";
import { IAsyncData, buildAsyncData } from "@/domains/async/AsyncData";

type CollectionItemTuple = [collection_id: Uuid, item_id: Uuid, model_id: Uuid];

export class CollectionItemListObservable {
  private store: AppStore;
  private collectionId: Uuid;

  liveQuerySubscription?: Subscription;
  subscribedCollectionItems: IAsyncData<CollectionItemTuple[]> = buildAsyncData({});

  constructor({ collectionId, store }: { collectionId: Uuid } & WithAppStore) {
    this.store = store;
    this.collectionId = collectionId;

    makeObservable<this, "store" | "collectionId">(this, {
      findItemAsync: true,
      getAllItemIdsAsync: true,
      getAllItemsAsync: true,
      hasItemAsync: true,
      getAllCollectionItemsAsync: true,
      store: false,
      collectionId: observable,

      liveQuerySubscription: observable,
      subscribedCollectionItems: observable,
      subscribeLiveQuery: action,
      unsubscribeLiveQuery: action,

      sizeData: computed,
      allItemIds: computed,
      getAllCollectionItemIdsAsync: true,
      allCollectionItemIds: computed,
      allItems: computed,
      allCollectionItems: computed,
      hasItem: false,
      findItem: false,
      addItem: action,
      removeItem: action,
      deleteAll: action,
    });

    onBecomeObserved(this, "subscribedCollectionItems", () => this.subscribeLiveQuery());
    onBecomeUnobserved(this, "subscribedCollectionItems", () => this.unsubscribeLiveQuery());
  }

  subscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
    this.liveQuerySubscription = liveQuery(async () => {
      const tuples = (await this.store.collectionItems.localTable
        .where("[collection_id+item_id+model_id]")
        .between(
          [this.collectionId, Dexie.minKey, Dexie.minKey],
          [this.collectionId, Dexie.maxKey, Dexie.maxKey]
        )
        .keys()) as unknown as CollectionItemTuple[];
      const itemIds = tuples.map(([_collection_id, item_id, _model_id]) => item_id);
      const availableNoteIds = await this.store.notes.localTable
        .where("model_id")
        .anyOf(itemIds)
        .and(note => note.is_available === 1)
        .primaryKeys();
      const availableNoteIdsSet = new Set(availableNoteIds);
      return tuples.filter(tuple => availableNoteIdsSet.has(tuple[1]));
    }).subscribe({
      next: rows => {
        runInAction(async () => {
          this.subscribedCollectionItems = buildAsyncData({
            data: rows as unknown as CollectionItemTuple[],
          });
        });
      },
    });
  }

  unsubscribeLiveQuery() {
    this.liveQuerySubscription?.unsubscribe();
  }

  async getAllCollectionItemIdsAsync(): Promise<Uuid[]> {
    const collectionItemIds = await this.store.collectionItems.localTable
      .where({ collection_id: this.collectionId })
      .primaryKeys();
    return collectionItemIds as unknown as Uuid[];
  }

  get allCollectionItemIds(): Set<Uuid> {
    if (!this.subscribedCollectionItems.data) {
      return new Set();
    }

    return new Set(
      this.subscribedCollectionItems.data.map(
        ([_collection_id, _item_id, collection_item_id]) => collection_item_id
      )
    );
  }

  get allItemIds(): Set<Uuid> {
    if (!this.subscribedCollectionItems.data) {
      return new Set();
    }

    return new Set(
      this.subscribedCollectionItems.data.map(
        ([_collection_id, item_id, _collection_item_id]) => item_id
      )
    );
  }

  async getAllItemIdsAsync(): Promise<Uuid[]> {
    const tuples = await this.store.collectionItems.localTable
      .where({ collection_id: this.collectionId })
      .toArray();
    return tuples.map(tuple => tuple.item_id);
  }

  get sizeData(): IAsyncData<number> {
    if (!this.subscribedCollectionItems.data) {
      return buildAsyncData({
        isLoading: true,
      });
    }

    return buildAsyncData({ data: this.subscribedCollectionItems.data.length });
  }

  get allItems(): INoteObservable[] {
    return Array.from(this.allItemIds)
      .map(id => this.store.notes.get(id))
      .filter(item => !!item);
  }

  async getAllItemsAsync(): Promise<INoteObservable[]> {
    const allItemIds = await this.getAllItemIdsAsync();
    const allItems = await Promise.all(allItemIds.map(id => this.store.notes.getAsync(id)));
    return allItems.filter(item => !!item);
  }

  get allCollectionItems(): CollectionItemObservable[] {
    return Array.from(this.allCollectionItemIds)
      .map(id => this.store.collectionItems.get(id))
      .filter(item => !!item);
  }

  async getAllCollectionItemsAsync(): Promise<CollectionItemObservable[]> {
    const collectionItemIds = await this.getAllCollectionItemIdsAsync();
    const collectionItems = await Promise.all(
      collectionItemIds.map(id => this.store.collectionItems.getAsync(id))
    );
    return collectionItems.filter(collectionItem => !!collectionItem);
  }

  public hasItem({ itemId }: { itemId: string }) {
    return this.allItemIds.has(itemId);
  }

  public async hasItemAsync({ itemId }: { itemId: string }) {
    const allItemIds = await this.getAllItemIdsAsync();
    return allItemIds.includes(itemId);
  }

  public findItem({ itemId }: { itemId: string }) {
    return this.allItemIds.has(itemId) ? this.store.notes.get(itemId) : undefined;
  }

  public async findItemAsync({ itemId }: { itemId: string }) {
    return this.store.notes.getAsync(itemId);
  }

  public async addItem({
    itemId,
    itemKind,
    triggerSuccessToast,
    eventContext,
  }: {
    itemId: string;
    itemKind: CollectionItemKind;
    triggerSuccessToast?: boolean;
    eventContext: EventContext;
  }) {
    if (this.hasItem({ itemId })) return;
    if (itemKind === "NOTE") {
      const queue = this.store.notes.getNoteQueue({ noteId: itemId });
      queue.push(
        new AddNoteToCollectionOperation({
          store: this.store,
          payload: {
            collection_id: this.collectionId,
            note_id: itemId,
          },
          triggerSuccessToast,
          eventContext,
        })
      );
    } else throw new Error("[CollectionItemListObservable] Unsupported item kind");
  }

  public async removeItem({ itemId }: { itemId: string }) {
    const collectionItem = await this.findItemAsync({ itemId });
    if (!collectionItem) return;

    const queue = this.store.notes.getNoteQueue({ noteId: itemId });
    queue.push(
      new RemoveNoteFromCollectionOperation({
        store: this.store,
        payload: {
          collection_id: this.collectionId,
          note_id: itemId,
        },
      })
    );
  }

  public async deleteAll() {
    const allItemIds = await this.getAllItemIdsAsync();
    for (const itemId of allItemIds) await this.removeItem({ itemId });
  }
}
