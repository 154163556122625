import { useAppStore } from "@/store";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ImportInProgressBannerContent } from "@/components/banners/import-in-progress/import-in-progress-banner-content";

interface ImportInProgressBannerProps {}

export const ImportInProgressBanner: FC<ImportInProgressBannerProps> = observer(() => {
  const { store } = useAppStore();

  if (!store.dataImports.isImportInProgress) {
    return null;
  }

  return <ImportInProgressBannerContent handleGoToImports={store.navigation.goToImportFrom1dot0} />;
});
