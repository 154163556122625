import { enumModule } from "@/modules/enum";
import { SortByKind } from "@/modules/lenses/types";
import {
  SEARCH_ENGINE_PARAM_KEY_SORT_ORDER,
  SearchEngineParamKey,
  SearchEngineParams,
} from "@/modules/url-params/search-engine-params/types";
import {
  SearchEngineRequestFacetFilter,
  SearchEngineRequestFilter,
  ValidSearchSortByValue,
} from "@/store/pages/SearchPageStore/types";
import { compact, isArray, isNull, isString } from "lodash-es";
import queryString, { ParsedQuery } from "query-string";

export const stringifySearchEngineParams = ({
  searchParams,
}: {
  searchParams: Partial<SearchEngineParams>;
}): string => {
  const encodedParams = encodeSearchEngineParams({ searchParams });

  return queryString.stringify(encodedParams, {
    sort: (itemLeft, itemRight) => {
      const matchingLeftKey = enumModule.findMatchingStringValue(SearchEngineParamKey, itemLeft);
      const matchingRightKey = enumModule.findMatchingStringValue(SearchEngineParamKey, itemRight);

      /**
       * We maintain a stable sort even if one of the keys is not found.
       */
      if (!matchingLeftKey) {
        return -1;
      }

      if (!matchingRightKey) {
        return 1;
      }

      return (
        SEARCH_ENGINE_PARAM_KEY_SORT_ORDER[matchingLeftKey] -
        SEARCH_ENGINE_PARAM_KEY_SORT_ORDER[matchingRightKey]
      );
    },
    encode: true,
    arrayFormat: "bracket",
    skipNull: true,
  });
};

export const encodeSearchEngineParams = ({
  searchParams,
}: {
  searchParams: Partial<SearchEngineParams>;
}): ParsedQuery => {
  /**
   * TODO - In the future, we can use the `query-string` library to do better
   * encoding here - e.g. using an easily readable format for arrays instead
   * of JSON stringification.
   */

  const encodedQueryStr = (() => {
    if (!searchParams.queryString) {
      return null;
    }

    return searchParams.queryString.toString();
  })();

  const encodedFilters = (() => {
    if (!searchParams.filters) {
      return null;
    }

    return searchParams.filters.map(filter => JSON.stringify(filter));
  })();

  const encodedFacetFilters = (() => {
    if (!searchParams.facetFilters) {
      return null;
    }

    return searchParams.facetFilters.map(facetFilter => JSON.stringify(facetFilter));
  })();

  const encodedSortBy = (() => {
    if (!searchParams.sortBy) {
      return null;
    }

    return searchParams.sortBy.toString();
  })();

  return {
    [SearchEngineParamKey.QueryStr]: encodedQueryStr,
    [SearchEngineParamKey.Filter]: encodedFilters,
    [SearchEngineParamKey.FacetFilter]: encodedFacetFilters,
    [SearchEngineParamKey.SortBy]: encodedSortBy,
  };
};

export const decodeSearchEngineParams = ({
  searchQuery,
}: {
  searchQuery: ParsedQuery;
}): SearchEngineParams => {
  const decodedQueryString = (() => {
    const queryString = searchQuery[SearchEngineParamKey.QueryStr];

    if (!isString(queryString)) {
      return "";
    }

    return queryString;
  })();

  const decodedFilters = (() => {
    const filterValue = searchQuery[SearchEngineParamKey.Filter];

    if (!isArray(filterValue)) {
      return [];
    }

    const parsedFilters = filterValue.map(filter => {
      if (isNull(filter)) {
        return null;
      }

      const parsedFilter = JSON.parse(filter);

      /**
       * Probably better to use something like `zod` here
       * in the future.
       */
      return parsedFilter as SearchEngineRequestFilter;
    });

    return compact(parsedFilters);
  })();

  const decodedFacetFilters = (() => {
    const facetFilterValue = searchQuery[SearchEngineParamKey.FacetFilter];

    if (!isArray(facetFilterValue)) {
      return [];
    }

    const parsedFacetFilters = facetFilterValue.map(facetFilter => {
      if (isNull(facetFilter)) {
        return null;
      }

      const parsedFacetFilter = JSON.parse(facetFilter);

      /**
       * Probably better to use something like `zod` here
       * in the future.
       */
      return parsedFacetFilter as SearchEngineRequestFacetFilter;
    });

    return compact(parsedFacetFilters);
  })();

  const decodedSortBy = (() => {
    const sortBy = searchQuery[SearchEngineParamKey.SortBy];
    if (!isString(sortBy)) {
      return SortByKind.LastCreated;
    }

    return sortBy;
  })();

  return {
    queryString: decodedQueryString,
    filters: decodedFilters,
    facetFilters: decodedFacetFilters,
    sortBy: decodedSortBy as ValidSearchSortByValue,
  };
};

export const parseSearchEngineParams = ({
  searchQueryStr,
}: {
  searchQueryStr: string;
}): SearchEngineParams => {
  const searchQuery = queryString.parse(searchQueryStr, {
    decode: true,
    arrayFormat: "bracket",
  });

  return decodeSearchEngineParams({ searchQuery });
};
