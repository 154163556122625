import { ApiSchema } from "@/modules/api";
import { NoteContentDocumentModelData, NoteModelData } from "@/store/note/types";
import { CollectionMetadataModelData, CollectionModelData } from "@/store/collections/types";
import { CollectionItemModelData } from "@/store/collection-items/types";
import { FavoriteItemModelData } from "@/store/favorite-items/types";
import {
  SpaceAccountCollectionModelData,
  SpaceAccountNoteModelData,
} from "@/store/recent-items/types";
import { SavedSearchModelData } from "@/store/saved-searches/types";
import { Uuid } from "@/domains/global/identifiers";
import { ExternalOperationKind, SerializedSyncOperation } from "@/store/sync/operations/types";
import { ContactModelData, SpaceAccountContactModelData } from "@/store/contacts/types";
import { AssertTrue, ValidateEnumIsSubtype } from "@/domains/typing/types";
import {
  ChatConversationModelData,
  ChatMessageModelData,
  SpaceAccountChatMessageModelData,
} from "@/store/chat/types";
import { SyncModelScope } from "@/domains/sync-scopes/types";
import { SpaceAccountFeatureFlagsModelData } from "@/store/feature-flags/types";
import { SpaceAccountTopicItemModelData, SpaceAccountTopicModelData } from "@/store/topics/types";
import { DataImportSyncModelData } from "@/store/data-imports/types";
import { SpaceAccountAppCalloutModelData } from "@/store/app-callout/types";

export type AcknowledgedOperation<T> = T & {
  latest_space_account_sequence_id: number;
};

export type OptimisticUpdate<T> = T & {
  optimistic_update_id: Uuid;
  locally_committed_at: string;
};

export type SyncModelKindValues = ApiSchema["SyncModelKind"];

export enum SyncModelKind {
  Note = "NOTE",
  NoteContentDocument = "NOTE_CONTENT_DOCUMENT",
  Collection = "COLLECTION",
  CollectionItem = "COLLECTION_ITEM",
  CollectionMetadata = "COLLECTION_METADATA",
  FavoriteItem = "FAVORITE_ITEM",
  SpaceAccountNote = "SPACE_ACCOUNT_NOTE",
  SpaceAccountCollection = "SPACE_ACCOUNT_COLLECTION",
  SavedSearch = "SAVED_SEARCH",
  Contact = "CONTACT",
  SpaceAccountContact = "SPACE_ACCOUNT_CONTACT",
  ChatConversation = "CHAT_CONVERSATION",
  ChatMessage = "CHAT_MESSAGE",
  SpaceAccountChatMessage = "SPACE_ACCOUNT_CHAT_MESSAGE",
  SpaceAccountFeatureFlagsConfig = "SPACE_ACCOUNT_FEATURE_FLAGS_CONFIG",
  DataImport = "DATA_IMPORT",
  DataImportItem = "DATA_IMPORT_ITEM",
  SpaceAccountTopic = "SPACE_ACCOUNT_TOPIC",
  SpaceAccountTopicItem = "SPACE_ACCOUNT_TOPIC_ITEM",
  SpaceAccountAppCallout = "SPACE_ACCOUNT_APP_CALLOUT",
}

type _ValidateSyncModelKindValues = AssertTrue<
  ValidateEnumIsSubtype<SyncModelKind, SyncModelKindValues>
>;

export type ListSyncUpdatesResponse = ApiSchema["ListSyncUpdatesResponseSchema"];
export type SyncUpdateResponse = ListSyncUpdatesResponse["results"][number];

export type UpsertedSyncUpdateResponse = ApiSchema["UpsertedSyncUpdateResponseSchema"];
export type DeletedSyncUpdateResponse = ApiSchema["DeletedSyncUpdateResponseSchema"];
export type AclUpsertedSyncUpdateResponse = ApiSchema["AclUpsertedSyncUpdateResponseSchema"];
export type AclRevokedSyncUpdateResponse = ApiSchema["AclRevokedSyncUpdateResponseSchema"];

export type BaseCombinedSyncUpdate =
  | UpsertedSyncUpdateResponse
  | DeletedSyncUpdateResponse
  | AclUpsertedSyncUpdateResponse
  | AclRevokedSyncUpdateResponse;

export type SyncUpdateShared = Omit<
  BaseCombinedSyncUpdate,
  "sync_id" | "locally_committed_at" | "committed_at"
>;

export type SyncOperation = ApiSchema["SubmitSyncOperationRequest"];
export type SyncOperationKind = SyncOperation["operation_kind"];
export type AcknowledgedSyncOperation = AcknowledgedOperation<SyncOperation>;

// NOTE: This is a temporary type until the backend supports guest mode sync operations
export type GuestSyncOperation = ApiSchema["SubmitSyncOperationRequest"];
export type GuestSyncOperationKind = GuestSyncOperation["operation_kind"];
export type AcknowledgedGuestSyncOperation = AcknowledgedOperation<GuestSyncOperation>;
/**
 * @todo - Swap this to use the real `ListGuestModeSyncUpdatesResponseSchema` once it is
 * implemented on the backend.
 */
export type GuestListSyncUpdatesResponse = ApiSchema["ListSyncUpdatesResponseSchema"];

export type GenericSyncOperationKind =
  | SyncOperationKind
  | GuestSyncOperationKind
  | ExternalOperationKind;

export type SyncModelData =
  | NoteModelData
  | NoteContentDocumentModelData
  | CollectionModelData
  | CollectionItemModelData
  | SavedSearchModelData
  | FavoriteItemModelData
  | ContactModelData
  | SpaceAccountContactModelData
  | CollectionMetadataModelData
  | SpaceAccountNoteModelData
  | SpaceAccountCollectionModelData
  | ChatMessageModelData
  | ChatConversationModelData
  | SpaceAccountChatMessageModelData
  | SpaceAccountFeatureFlagsModelData
  | SpaceAccountTopicModelData
  | SpaceAccountTopicItemModelData
  | DataImportSyncModelData
  | SpaceAccountAppCalloutModelData;

export type SyncUpdateValue<ModelData extends SyncModelData> = {
  model_id: string;
  model_kind: SyncModelKindValues;
  model_version: number;
  model_data: ModelData;
  model_scopes: SyncModelScope[];
};
export type SyncUpdatePartialValue = ApiSchema["SyncUpdatePartialValueResponseSchema"];
export type SyncModelUpsertedSyncUpdate<ModelData extends SyncModelData> = SyncUpdateShared & {
  value: SyncUpdateValue<ModelData>;
};
export type SyncModelDeletedSyncUpdate = SyncUpdateShared & {
  value: SyncUpdatePartialValue;
};
export type SyncUpdate<ModelData extends SyncModelData> =
  | SyncModelUpsertedSyncUpdate<ModelData>
  | SyncModelDeletedSyncUpdate;

export type OptimisticSyncUpdate<ModelData extends SyncModelData> = OptimisticUpdate<
  SyncUpdate<ModelData>
>;

// TODO: This should be codegen'd, but not sure why it's not

export interface BootstrapSyncUpdateResponse {
  data?: ApiSchema["BootstrapSyncUpdatesResponseSchema"];
  // TODO: any for now until codgen is fixed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}
export interface BootstrapSyncUpdatesPaginatedResponse {
  data?: ApiSchema["BootstrapSyncUpdatesPaginatedResponseSchema"];
  // TODO: any for now until codgen is fixed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}
export interface BootstrapGuestModeSyncUpdateResponse {
  // TODO - Update when guest mode support is implemented
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  // TODO: any for now until codgen is fixed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}
export interface HydrationSyncUpdateResponse {
  data?: ApiSchema["HydrationSyncUpdatesResponseSchema"];
  // TODO: any for now until codgen is fixed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}

export enum SyncQueueItemKind {
  SyncOperation = "SyncOperation",
  AcknowledgedSyncOperation = "AcknowledgedSyncOperation",
  OptimisticUpdate = "OptimisticUpdate",
}

export type SyncQueueItem =
  | { kind: SyncQueueItemKind.SyncOperation; data: SerializedSyncOperation }
  | { kind: SyncQueueItemKind.AcknowledgedSyncOperation; data: SerializedSyncOperation }
  | { kind: SyncQueueItemKind.OptimisticUpdate; data: OptimisticSyncUpdate<SyncModelData> };

export const ACL_SYNC_OPERATIONS: GenericSyncOperationKind[] = [
  "GRANT_NOTE_ACL_VIA_SPACE_ACCOUNT",
  "GRANT_NOTE_ACL_VIA_EMAIL_ADDRESS",
  "UPDATE_NOTE_ACL_VIA_SPACE_ACCOUNT",
  "UPDATE_NOTE_ACL_VIA_EMAIL_ADDRESS",
  "UPDATE_NOTE_ACL_VIA_COLLECTION",
  "REVOKE_NOTE_ACL_VIA_SPACE_ACCOUNT",
  "REVOKE_NOTE_ACL_VIA_EMAIL_ADDRESS",
  "GRANT_COLLECTION_ACL_VIA_SPACE_ACCOUNT",
  "GRANT_COLLECTION_ACL_VIA_EMAIL_ADDRESS",
  "UPDATE_COLLECTION_ACL_VIA_SPACE_ACCOUNT",
  "UPDATE_COLLECTION_ACL_VIA_EMAIL_ADDRESS",
  "REVOKE_COLLECTION_ACL_VIA_SPACE_ACCOUNT",
  "REVOKE_COLLECTION_ACL_VIA_EMAIL_ADDRESS",
];

export const ACL_SYNC_OPERATIONS_FOR_NOTES: GenericSyncOperationKind[] = [
  ...ACL_SYNC_OPERATIONS,
  "ADD_NOTE_TO_COLLECTION",
  "REMOVE_NOTE_FROM_COLLECTION",
];

export enum QueueProcessingState {
  NotReady = "NotReady",
  Ready = "Ready",
  Processing = "Processing",
  Paused = "Paused",
}

export enum PreloadingState {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Complete = "Complete",
  Failed = "Failed",
}

export type SyncCustomErrorData = ApiSchema["SubmitSyncOperationFailedCustomResponseData"];

export type SerializedOptimisticUpdate<ModelData extends SyncModelData> =
  OptimisticSyncUpdate<ModelData> & {
    sync_operation_id: Uuid;
    model_id: string;
    model_kind: SyncModelKindValues;
  };
