import { filter, uniq } from "lodash-es";
import { useEffect } from "react";
import { action, computed, makeObservable, observable } from "mobx";

import { MultiSelectActions } from "@/components/multi-select-actions/MultiSelectActions";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { AddToCollectionModalStore } from "@/components/modal/add-to-collection/AddToCollectionModalStore";
import { AddToCollectionModal } from "@/components/modal/add-to-collection/AddToCollectionModal";
import { RemoveNotesFromSharedCollectionModal } from "@/components/modal/remove-notes-from-shared-collection/RemoveNotesFromSharedCollectionModal";
import { RemoveNotesFromSharedCollectionModalStore } from "@/components/modal/remove-notes-from-shared-collection/RemoveNotesFromSharedCollectionModalStore";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { INoteObservable } from "@/store/note";
import { actions } from "@/actions";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { DeleteSharedNotesModal } from "@/components/modal/delete-shared-notes/DeleteSharedNotesModal";
import { DeleteNotePermanentlyModalStore } from "@/components/modal/delete-notes-permanently/DeleteNotePermanentlyModalStore";
import { IMdsListState } from "@/design-system/components/item-list/types";
import { EventContext } from "@/domains/metrics/context";
import { notesModule } from "@/modules/notes";
import { toastModule } from "@/modules/toast";

export interface ListStateProvider {
  orderedItemIds: string[];
  collectionMultiSelectActionsContext?: CollectionObservable;
  supportsMoveToTrashListAction?: boolean;
  supportsRestoreListAction?: boolean;
  supportsAddToCollectionListAction?: boolean;
  supportsRemoveFromCollectionListAction?: boolean;
  eventContext: EventContext;
}

export class ListStateObservable extends AppSubStore implements IMdsListState {
  anchorSelectedItemId?: string;
  selectedItemIds: string[] = [];
  highlightedItemId?: string;
  listStateProvider: ListStateProvider;
  deleteNotePermanentlyModal?: DeleteNotePermanentlyModalStore;
  isCopying = false;

  constructor({
    listStateProvider,
    deleteNotePermanentlyModal,
    ...injectedDeps
  }: AppSubStoreArgs & {
    listStateProvider: ListStateProvider;
    deleteNotePermanentlyModal?: DeleteNotePermanentlyModalStore;
  }) {
    super(injectedDeps);

    this.deleteNotePermanentlyModal = deleteNotePermanentlyModal;
    this.listStateProvider = listStateProvider;

    makeObservable<
      this,
      "addToCollectionModalStore" | "deleteSharedNotesModalStore" | "removeFromCollectionModalStore"
    >(this, {
      anchorSelectedItemId: observable,
      selectedItemIds: observable,
      highlightedItemId: observable,
      listStateProvider: observable,
      isCopying: observable,
      deleteNotePermanentlyModal: false,
      isHoverable: computed,
      isSelectModeActive: computed,
      canAddToCollection: computed,
      canRemoveFromCollection: computed,
      canMoveToTrash: computed,
      canRestoreFromTrash: computed,
      multiSelectUi: computed,
      isSelected: false,
      toggleItemSelection: action,
      selectItemsFromLastSelectionUntil: action,
      clearSelection: action,
      addToCollectionModalStore: false,
      deleteSharedNotesModalStore: false,
      removeFromCollectionModalStore: false,
      highlightItem: action,
      handleAddToCollection: action,
      handleRemoveFromCollection: action,
      handleRestore: action,
      handleDelete: action,
      handleCopySelectedNotes: action,
      reset: action,
      useListStateEffects: false,
    });
  }

  get isHoverable() {
    return this.store.publicAppStore.interface.isHoverable;
  }

  get isSelectModeActive() {
    return this.selectedItemIds.length > 0;
  }

  get canAddToCollection() {
    return this.listStateProvider.supportsAddToCollectionListAction;
  }

  get canRemoveFromCollection() {
    return (
      !!this.listStateProvider.supportsRemoveFromCollectionListAction &&
      !!this.listStateProvider.collectionMultiSelectActionsContext
    );
  }

  get canMoveToTrash() {
    return this.listStateProvider.supportsMoveToTrashListAction;
  }

  get canRestoreFromTrash() {
    return this.listStateProvider.supportsRestoreListAction;
  }

  get multiSelectUi() {
    return (
      <>
        <MultiSelectActions listState={this} />
        <AddToCollectionModal store={this.addToCollectionModalStore} />
        <DeleteSharedNotesModal store={this.deleteSharedNotesModalStore} />
        <RemoveNotesFromSharedCollectionModal store={this.removeFromCollectionModalStore} />
      </>
    );
  }

  isSelected = (itemId: string) => this.selectedItemIds.includes(itemId);

  toggleItemSelection = (itemId: string) => {
    if (this.selectedItemIds.includes(itemId)) {
      this.selectedItemIds = this.selectedItemIds.filter(e => e !== itemId);
      return;
    }
    this.anchorSelectedItemId = itemId;
    this.selectedItemIds.push(itemId);
  };

  selectItemsFromLastSelectionUntil = (itemId: string) => {
    const { orderedItemIds } = this.listStateProvider;
    const lastSelectedIndex = this.anchorSelectedItemId
      ? orderedItemIds.indexOf(this.anchorSelectedItemId)
      : 0;
    const nextSelectedIndex = orderedItemIds.indexOf(itemId);

    const [startIndex, endIndex] =
      lastSelectedIndex < nextSelectedIndex
        ? [lastSelectedIndex, nextSelectedIndex + 1]
        : [nextSelectedIndex, lastSelectedIndex + 1];

    const newSelectedItems = orderedItemIds.slice(startIndex, endIndex);
    this.selectedItemIds = uniq([...this.selectedItemIds, ...newSelectedItems]);
  };

  clearSelection = () => {
    this.anchorSelectedItemId = undefined;
    this.selectedItemIds = [];
  };

  private addToCollectionModalStore = new AddToCollectionModalStore(this, {
    handleConfirm: this.clearSelection,
  });
  private deleteSharedNotesModalStore = new DeleteSharedNotesModalStore(this, {
    handleConfirm: this.clearSelection,
  });
  private removeFromCollectionModalStore = new RemoveNotesFromSharedCollectionModalStore(this, {
    handleConfirm: this.clearSelection,
  });

  highlightItem = (itemId?: string) => {
    this.highlightedItemId = itemId;
  };

  handleAddToCollection = () => {
    this.addToCollectionModalStore.open({
      noteIds: this.selectedItemIds,
      collection: this.listStateProvider.collectionMultiSelectActionsContext,
      eventContext: this.listStateProvider.eventContext,
    });
  };

  handleRemoveFromCollection = () => {
    const collection = this.listStateProvider.collectionMultiSelectActionsContext;
    if (!collection) return;

    if (!collection.isShared) {
      const noteIds = this.selectedItemIds;

      this.clearSelection();
      actions.removeNotesFromCollection({
        collection,
        noteIds,
        store: this.store,
      });
      return;
    }

    const notes = filter(
      this.selectedItemIds.map(noteId => this.store.notes.getNoteObservableById({ noteId }))
    ) as INoteObservable[];
    this.removeFromCollectionModalStore.open({ collection, notes });
  };

  handleRestore = () => {
    const notes = filter(
      this.selectedItemIds.map(noteId => this.store.notes.getNoteObservableById({ noteId }))
    ) as INoteObservable[];
    this.clearSelection();
    actions.restoreNotesFromTrash({ notes, store: this.store });
  };

  handleDelete = () => {
    if (this.deleteNotePermanentlyModal) {
      const notes = filter(
        this.selectedItemIds.map(noteId => this.store.notes.getNoteObservableById({ noteId }))
      ) as INoteObservable[];
      this.deleteNotePermanentlyModal.open({ notes });
      return;
    }

    const notes = filter(
      this.selectedItemIds.map(noteId => this.store.notes.getNoteObservableById({ noteId }))
    ) as INoteObservable[];
    const isShared = notes.some(note => note.isShared);
    if (isShared) {
      this.deleteSharedNotesModalStore.open(...notes);
      return;
    }

    this.clearSelection();
    actions.moveNotesToTrash({ notes, store: this.store });
  };

  handleCopySelectedNotes = async () => {
    if (this.isCopying) return;

    try {
      this.isCopying = true;
      // Get notes in the same order as they appear in the list
      const notes = this.listStateProvider.orderedItemIds
        .filter(id => this.selectedItemIds.includes(id))
        .map(noteId => this.store.notes.getNoteObservableById({ noteId }))
        .filter(note => note) as INoteObservable[];

      // Build XML string for each note
      const xmlStrings = await Promise.all(
        notes.map(async (note, index) => {
          // Wait for note content to load if not already loaded
          if (!note.isNoteContentDocumentLoaded) {
            await note.fetchNoteContentDocument();
          }

          // Get author names, falling back to "Unknown User" if not available
          const authors = note.authors
            .map(author => author.profileDisplayName || "Unknown User")
            .join(", ");

          // Get primary owner name, falling back to "Unknown User" if not available
          const primaryOwnerName = note.primaryOwner?.profileDisplayName || "Unknown User";

          // Get collection names
          const collections = note.collectionList?.allCollections
            .map(collection => collection.title)
            .join(", ");

          const content = note.noteContentDocument?.data?.remoteContent || "";
          const { mdx } = notesModule.convertEncodedContentToNoteContent(content);

          return `<note id="${note.id}" index="${index}">
  <head>
    <title>${note.title}</title>
    <created_at>${note.createdAt}</created_at>
    <created_by>${primaryOwnerName}</created_by>
    <last_edited_at>${note.modifiedAt}</last_edited_at>
    <edited_by>${authors}</edited_by>
    <in_collections>${collections}</in_collections>
  </head>
  <body>
    ${mdx}
  </body>
</note>`;
        })
      );

      // Combine all notes into a single XML string
      const combinedXml = xmlStrings.join("\n");

      // Add a small delay to make loading state visible
      await new Promise(resolve => setTimeout(resolve, 500));

      // Copy to clipboard
      await navigator.clipboard.writeText(combinedXml);

      // Show success toast
      toastModule.triggerToast({
        content: `${notes.length} note${notes.length === 1 ? "" : "s"} copied to clipboard`,
      });
    } finally {
      this.isCopying = false;
    }
  };

  reset = () => {
    this.anchorSelectedItemId = undefined;
    this.selectedItemIds = [];
    this.highlightedItemId = undefined;
    this.addToCollectionModalStore.close();
  };

  useListStateEffects() {
    useEffect(() => () => this.reset(), []);
  }
}
