export enum TrackedEvent {
  NoteView = "note_view",
  NotesView = "notes_view",
  NoteCreate = "note_create",
  NoteAddToCollection = "note_add_to_collection",
  NoteShare = "note_share",
  CollectionView = "collection_view",
  CollectionsView = "collections_view",
  CollectionCreate = "collection_create",
  CollectionShare = "collection_share",
  ChatView = "chat_view",
  ChatMessageSend = "chat_message_send",
  ChatMessageUpvote = "chat_message_upvote",
  ChatMessageDownvote = "chat_message_downvote",
  SearchView = "search_view",
  SearchResultsOpenNote = "search_results_open_note",
  SearchResultsOpenCollection = "search_results_open_collection",
  QuickSearchView = "quick_search_view",
  QuickSearchOpenCollection = "quick_search_open_collection",
  QuickSearchOpenNote = "quick_search_open_note",
  CopilotOpenNote = "copilot_open_note",
  CopilotUpvoteNote = "copilot_upvote_note",
  CopilotDownvoteNote = "copilot_downvote_note",
  ImportFrom1Dot0StartImport = "import_from_1_dot_0_start_import",
  PerformanceHeartbeat = "performance_heartbeat",
  SyncErrorShown = "sync_error_shown",
  AppCalloutBannerActionClicked = "app_callout_banner_action_clicked",
  AppCalloutBannerDismissed = "app_callout_banner_dismissed",
}
