import { Maybe } from "@/domains/common/types";
import { api } from "@/modules/api";
import { logger } from "@/modules/logger";
import { objectModule } from "@/modules/object";
import { AppStore } from "@/store/AppStore";
import { uuidModule } from "@/modules/uuid";
import { NoteContentDocumentObservable } from "@/store/note/NoteContentDocumentObservable";
import { NoteContentDocumentModelData } from "@/store/note/types";
import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import {
  HydrationSyncUpdateResponse,
  OptimisticSyncUpdate,
  PreloadingState,
  SyncModelData,
  SyncModelKind,
  SyncUpdate,
  SyncUpdateValue,
} from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { isClientUpgradeError } from "@/store/utils/errors";
import { debounce } from "lodash-es";
import { makeObservable, runInAction, action, observable, override, ObservableMap } from "mobx";
import pRetry, { AbortError, FailedAttemptError } from "p-retry";
import { NoteContentDocumentIndexes } from "@/store/note/NoteContentDocumentIndexes";

const NOTE_CONTENT_PRELOADING_DISABLED_KEY = "note_content_preloading_disabled";
const MAX_REFETCH_COUNT = 5;

export class AppStoreNoteContentDocumentStore extends BaseSyncModelStore<
  NoteContentDocumentObservable,
  NoteContentDocumentModelData
> {
  public isPreloadingDisabled: boolean = false;
  public preloadingState: PreloadingState = PreloadingState.NotStarted;
  refetchCounter: ObservableMap<string, number> = new ObservableMap();

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.NoteContentDocument, ...injectedDeps });
    makeObservable<this>(this, {
      refetchCounter: observable,
      processSyncUpdate: override,
      isPreloadingDisabled: observable,
      preloadingState: observable,
      createSyncModel: false,
      fetch: action,
      debouncedFetch: action,
      initializePreloadingState: action,
      setIsPreloadingDisabled: action,
      togglePreloading: action,
      preloadAll: action,
      computeIndexes: false,
    });
    this.initializePreloadingState();
  }

  createSyncModel(
    updateValue: SyncUpdateValue<NoteContentDocumentModelData>
  ): NoteContentDocumentObservable {
    const noteContentDocument = new NoteContentDocumentObservable({
      id: updateValue.model_id,
      data: updateValue,
      store: this.store,
    });

    const noteId = updateValue.model_data.note_id;
    if (this.store.notes.pool.has(noteId)) {
      const note = this.store.notes.pool.get(noteId)?.deref();
      if (note) note.setNoteContentDocument(noteContentDocument);
    }

    return noteContentDocument;
  }

  public debouncedFetch = debounce(async (id: string) => this.fetch(id), 500, { maxWait: 2500 });

  async fetch(id: string): Promise<NoteContentDocumentObservable | undefined> {
    let hasError = false;
    let nextPageToken: string | undefined = undefined;
    const updates: SyncUpdate<SyncModelData>[] = [];

    do {
      try {
        const response = await pRetry(
          async () => {
            const resp = await api.get(`/v2/sync/updates/hydrate`, {
              params: {
                query: {
                  space_id: this.store.spaces.myPersonalSpaceId,
                  sync_model_kind: SyncModelKind.NoteContentDocument,
                  sync_model_ids: [id],
                },
              },
            });

            if (resp.error) {
              if (isClientUpgradeError(resp.error)) {
                this.store.sync.handleClientUpgradeError();
                throw new AbortError("Client upgrade error");
              }
              throw new Error(JSON.stringify(resp.error));
            }

            return resp;
          },
          {
            retries: 3,
            onFailedAttempt: (error: FailedAttemptError) => {
              logger.warn({
                message:
                  "[SYNC][AppStoreNoteContentDocumentStore] Retrying fetch note content document",
                info: { id, attempt: error.attemptNumber, retriesLeft: error.retriesLeft },
              });
            },
          }
        );

        const actions = response.data?.results || [];
        updates.push(...actions);
        nextPageToken = response.data?.hydration_session_cursor || undefined;
      } catch (e) {
        logger.error({
          message: "[SYNC][AppStoreNoteContentDocumentStore] Error fetching note content document",
          info: { id, error: objectModule.safeErrorAsJson(e as Error) },
        });
        hasError = true;
        break;
      }
    } while (nextPageToken);

    if (hasError) return; // Returning undefined should be treated as an error

    for (const update of updates) {
      await this.processSyncUpdate(update, { endpoint: "/v2/sync/updates/hydrate (one)" });
      /**
       * We generate a random ID for storing sync updates in the local DB.
       */
      const syncUpdateId = uuidModule.generate();
      await this.store.sync.db.syncUpdates.put(
        {
          ...update,
          /**
           * After we roll out CVRs, we can remove the syncUpdates table.
           *
           * TODO: @MacroMackie follow up with this on Monday, Nov 11.
           */
          sync_id: `deprecated-${syncUpdateId}`,
        },
        syncUpdateId
      );
    }

    return this.getAsync(id);
  }

  public async initializePreloadingState() {
    const storedValue = await localStorage.getItem(NOTE_CONTENT_PRELOADING_DISABLED_KEY);
    this.isPreloadingDisabled = storedValue === "true";
  }

  public setIsPreloadingDisabled(value: boolean) {
    this.isPreloadingDisabled = value;
    localStorage.setItem(NOTE_CONTENT_PRELOADING_DISABLED_KEY, value.toString());
  }

  public togglePreloading() {
    this.setIsPreloadingDisabled(!this.isPreloadingDisabled);
  }

  async preloadAll() {
    if (this.isPreloadingDisabled) {
      logger.debug({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents is disabled",
      });

      return;
    }

    const noteContentPreloadingState =
      await this.store.memDb.settings.getNoteContentPreloadingState();

    if (noteContentPreloadingState === PreloadingState.Complete) {
      logger.debug({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Note content documents preloading was already complete",
      });

      return;
    }

    if (
      this.preloadingState === PreloadingState.InProgress ||
      this.preloadingState === PreloadingState.Complete
    ) {
      logger.debug({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Note content documents preloading is already in progress or complete",
      });

      return;
    }

    console.debug("[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents...");
    runInAction(() => (this.preloadingState = PreloadingState.InProgress));

    let hasError = false;
    let nextPageToken: string | undefined =
      (await this.store.memDb.settings.getLastHydrateCursor()) || undefined;
    let hasNextPage = false;

    logger.info({
      message: "[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents... (2)",
      info: { lastHydrateCursor: nextPageToken || "undefined" },
    });

    do {
      try {
        if (hasNextPage && !nextPageToken) {
          logger.error({
            message:
              "[SYNC][AppStoreNoteContentDocumentStore] Unexpected hydration_session_cursor while preloading note content documents",
            info: { hasNextPage, nextPageToken: nextPageToken || "undefined" },
          });

          return;
        }

        const response: HydrationSyncUpdateResponse = await api.get(`/v2/sync/updates/hydrate`, {
          params: {
            query: {
              space_id: this.store.spaces.myPersonalSpaceId,
              hydration_session_cursor: nextPageToken,
              only_sync_model_kinds: [SyncModelKind.NoteContentDocument],
            },
          },
        });

        if (response.error) {
          if (isClientUpgradeError(response.error)) this.store.sync.handleClientUpgradeError();
          hasError = true;
          break;
        }

        // Process updates and save cursor in a single transaction
        logger.info({
          message:
            "[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents: Processing updates and saving cursor...",
          info: { updates: response.data?.results?.length || 0 },
        });

        // Process updates
        await this.store.memDb.transaction(
          "rw",
          [
            ...Object.values(this.db.mappedTables).map(table => table.local),
            ...Object.values(this.db.mappedTables).map(table => table.remote),
            this.db.searchSuggestions,
            this.db.queue.optimisticUpdates,
            this.db.syncUpdates,
            this.db.settings.table,
          ],
          async () => {
            const updates = response.data?.results || [];
            await Promise.all(
              updates.map(update =>
                this.processSyncUpdate(update, { endpoint: "/v2/sync/updates/hydrate (all)" })
              )
            );

            // Save the cursor
            await this.store.memDb.settings.setLastHydrateCursor({
              cursor: response.data?.hydration_session_cursor || "",
            });
          }
        );

        nextPageToken = response.data?.hydration_session_cursor || undefined;
        hasNextPage = response.data?.has_next_page || false;

        logger.info({
          message:
            "[SYNC][AppStoreNoteContentDocumentStore] Preloading note content documents: Saved cursor",
          info: {
            cursor: response.data?.hydration_session_cursor || "undefined",
            hasNextPage,
            nextPageToken: nextPageToken || "undefined",
          },
        });
      } catch (e) {
        logger.error({
          message:
            "[SYNC][AppStoreNoteContentDocumentStore] Error preloading note content documents",
          info: { error: objectModule.safeErrorAsJson(e as Error) },
        });
        hasError = true;
        break;
      }
    } while (hasNextPage);

    if (hasError) {
      runInAction(() => (this.preloadingState = PreloadingState.Failed));
      logger.error({
        message:
          "[SYNC][AppStoreNoteContentDocumentStore] Failed to preload note content documents",
      });
      return;
    }

    runInAction(async () => {
      this.preloadingState = PreloadingState.Complete;
    });

    await this.store.memDb.settings.setNoteContentPreloadingState(PreloadingState.Complete);

    console.debug("[SYNC][AppStoreNoteContentDocumentStore] Note content documents preloaded");
  }

  async processSyncUpdate(
    update: SyncUpdate<SyncModelData>,
    { hydrating, endpoint = "/v2/sync-updates" }: { hydrating?: boolean; endpoint?: string } = {}
  ) {
    if (hydrating) {
      await super.processSyncUpdate(update);
      return;
    }

    const updateValue = update.value as SyncUpdateValue<NoteContentDocumentModelData>;

    if (update.kind == "UPSERTED" || update.kind == "ACL_UPSERTED") {
      if (updateValue.model_version > 1 && !updateValue.model_data.encoded_content) {
        logger.error({
          message: "[SYNC][AppStoreNoteContentDocumentStore] Received invalid note encoded content",
          info: { update, endpoint },
        });
        const refetchCount = this.refetchCounter.get(updateValue.model_id) || 0;
        if (refetchCount < MAX_REFETCH_COUNT) {
          this.debouncedFetch(updateValue.model_id);
          this.refetchCounter.set(updateValue.model_id, refetchCount + 1);
        } else {
          await super.processSyncUpdate(update);
        }
        return;
      }
    }

    this.refetchCounter.delete(updateValue.model_id);

    await super.processSyncUpdate(update);
  }

  public computeIndexes({
    store,
    remoteData,
    optimisticUpdates,
  }: {
    store: AppStore;
    remoteData: Maybe<SyncUpdateValue<NoteContentDocumentModelData>>;
    optimisticUpdates: OptimisticSyncUpdate<NoteContentDocumentModelData>[];
  }): Record<string, unknown> {
    return new NoteContentDocumentIndexes({ store, remoteData, optimisticUpdates }).indexes;
  }
}
