import styled from "@emotion/styled";
import { Button } from "react-aria-components";

export const StyledSwitchFloaty = styled(Button)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  padding: 7px ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.lilac.x100};
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
  box-shadow: ${({ theme }) => theme.shadows.popover};
  color: ${({ theme }) => theme.colors.grey.x0};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  background-color: ${({ theme }) => theme.colors.grey.x500};

  transition: 0.25s ease;

  &:active {
    transform: scale(0.99);
    background-color: ${({ theme }) => theme.colors.lilac.x300};
  }

  &[data-selected="true"] {
    background-color: ${({ theme }) => theme.colors.lilac.x100};

    &:hover {
      background-color: ${({ theme }) => theme.colors.lilac.x200};
    }
  }

  &:disabled {
    cursor: none;
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.grey.x300};
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    font-size: ${({ theme }) => theme.spacing.md};
    color: ${({ theme }) => theme.colors.grey.x0};
  }
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  max-width: 100px;
`;
