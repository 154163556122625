import { actions } from "@/actions";
import { DeleteCollectionModalStore } from "@/components/modal/delete-collection/DeleteCollectionModalStore";
import { DeleteSharedNotesModalStore } from "@/components/modal/delete-shared-notes/DeleteSharedNoteModalStore";
import { MakeCollectionPrivateModalStore } from "@/components/modal/make-collection-private/MakeCollectionPrivateStore";
import { RemoveNotesFromSharedCollectionModalStore } from "@/components/modal/remove-notes-from-shared-collection/RemoveNotesFromSharedCollectionModalStore";
import { ShareSheetModalStore } from "@/components/modal/share-sheet/ShareSheetModalStore";
import { ShareSheetEntityKind } from "@/components/modal/share-sheet/types";
import {
  MdsDropdownContentList,
  MdsDropdownItem,
  MdsDropdownItemKind,
} from "@/design-system/components/dropdown";
import { MdsIconKind } from "@/design-system/components/icon";
import { ItemPreviewState } from "@/design-system/components/item-list/rows/item-preview/ItemPreviewState";
import { MdsItemListRowFeaturedContent } from "@/design-system/components/item-list/rows/MdsItemListRowFeaturedContent";
import {
  MdsItemDropdown,
  MdsItemKind,
  MdsItemListItemRowData,
  MdsItemListRowData,
  MdsItemListRowType,
  MdsItemListSize,
  MdsSharedByVariant,
} from "@/design-system/components/item-list/types";
import { sortCollectionsForChips } from "@/domains/collections/sortCollectionsForChips";
import { Maybe } from "@/domains/common/types";
import { generateShortDateString } from "@/domains/date/date";
import { Uuid } from "@/domains/global/identifiers";
import localDb from "@/domains/local-db";
import { EventContext } from "@/domains/metrics/context";
import { getObjectAfterDeletion } from "@/hooks/useObjectAfterDeletion";
import { NotesListFilter } from "@/modules/filters/notes/NotesListFilter";
import { SortByKind } from "@/modules/lenses/types";
import { logger } from "@/modules/logger";
import { groupLens } from "@/modules/timeline";
import { uuidModule } from "@/modules/uuid";
import { resolveCollectionItemSyncModelUuid } from "@/modules/uuid/sync-models/resolveCollectionItemSyncModelUuid";
import { resolveNoteContentDocumentSyncModelUuid } from "@/modules/uuid/sync-models/resolveNoteContentDocumentSyncModelUuid";
import { CollectionObservable } from "@/store/collections/CollectionObservable";
import { INoteObservable, NotesIndexTuple, NotesSortByKind } from "@/store/note";
import { getRowForNoteObservable } from "@/store/note/getRowForNoteObservable";
import { LeaveCollectionMessageModalStore } from "@/store/pages/CollectionsViewPageStore/LeaveCollectionMessageModalStore";
import { ListStateObservable } from "@/store/pages/ListStateObservable";
import { MountedStore } from "@/store/pages/MountedStore";
import { AppSubStore, AppSubStoreArgs } from "@/store/types";
import { getLocalTimeZone } from "@internationalized/date";
import Dexie, { liveQuery, Subscription } from "dexie";
import {
  action,
  computed,
  makeObservable,
  observable,
  onBecomeObserved,
  reaction,
  runInAction,
} from "mobx";

export class CollectionsViewPageStore extends AppSubStore {
  overrideCollectionId?: string;

  supportsAddToCollectionListAction = true;
  supportsRemoveFromCollectionListAction = true;
  isLoading: boolean;

  listState: ListStateObservable;
  deleteCollectionModal: DeleteCollectionModalStore;
  deleteSharedNotesModal: DeleteSharedNotesModalStore;
  removeNotesFromSharedCollectionModal: RemoveNotesFromSharedCollectionModalStore;
  shareSheetModal: ShareSheetModalStore;
  makeCollectionPrivateModal: MakeCollectionPrivateModalStore;
  leaveCollectionMessageModal: LeaveCollectionMessageModalStore;

  mountState = new MountedStore();

  /** This is a proxy with isDeleted=true after the note is gone. */
  collectionObservable: Maybe<CollectionObservable>;
  couldAccessCollectionOnMount: Maybe<boolean>;

  shareSheetEntityId: Uuid | undefined;
  shareSheetEntityKind: ShareSheetEntityKind | undefined;

  isSubscriptionInitialized = false;
  liveQuerySubscription: Maybe<Subscription>;
  subscribedNoteRows: NotesIndexTuple[] = [];

  eventContext = EventContext.CollectionMultiselectActions;

  filters: NotesListFilter;

  private readonly supportedSortOptions: SortByKind[] = [
    SortByKind.LastModified,
    SortByKind.LastCreated,
    SortByKind.LastAdded,
  ];

  constructor(injectedDeps: AppSubStoreArgs) {
    super(injectedDeps);

    this.listState = new ListStateObservable({ ...injectedDeps, listStateProvider: this });
    this.deleteCollectionModal = new DeleteCollectionModalStore(injectedDeps, {});
    this.deleteSharedNotesModal = new DeleteSharedNotesModalStore(injectedDeps, {});
    this.removeNotesFromSharedCollectionModal = new RemoveNotesFromSharedCollectionModalStore(
      injectedDeps,
      {}
    );
    this.shareSheetModal = ShareSheetModalStore.forAppStore({ appStore: this.store });
    this.makeCollectionPrivateModal = new MakeCollectionPrivateModalStore(injectedDeps);
    this.filters = new NotesListFilter(this.store, this.supportedSortOptions);
    this.isLoading = true;

    makeObservable<
      this,
      | "generateItemDropdown"
      | "generateItemRow"
      | "initializeLiveQuery"
      | "supportedSortOptions"
      | "getNotesSort"
      | "getCollectionItemsFilter"
    >(this, {
      getNotesSort: true,
      getCollectionItemsFilter: true,
      useEffects: true,
      generateItemRow: true,
      subscribedNoteRows: true,
      isSubscriptionInitialized: true,
      liveQuerySubscription: true,
      initializeLiveQuery: true,
      supportsAddToCollectionListAction: false,
      supportsRemoveFromCollectionListAction: false,

      listState: false,
      deleteCollectionModal: false,
      deleteSharedNotesModal: false,
      removeNotesFromSharedCollectionModal: false,
      shareSheetModal: false,
      makeCollectionPrivateModal: false,
      leaveCollectionMessageModal: false,
      mountState: false,
      eventContext: false,
      filters: false,
      supportedSortOptions: false,
      // Mounting + Access Changes
      initialize: false,
      amIMounted: computed,
      showNotFound: true,
      collectionObservable: observable,
      setCollectionObservable: true,
      canAccessCollection: true,
      couldAccessCollectionOnMount: observable,
      setCouldAccessCollectionOnMount: true,
      shareSheetEntityId: observable,
      shareSheetEntityKind: observable,
      collectionId: computed,
      setCollectionId: action,
      overrideCollectionId: observable,
      collection: computed,
      collectionMultiSelectActionsContext: computed,
      generateItemDropdown: false,
      collectionItemRows: computed,
      orderedItemIds: computed,

      headerDropdownButtonContentList: computed,
      handleCreateNewNote: action,
      handleLeaveCollection: action,
      hasNoItems: computed,
      isLoading: observable,
      groupedLensItems: computed,
      itemRows: computed,
    });

    // We need this to be already observable to react to changes its observable props.
    this.leaveCollectionMessageModal = new LeaveCollectionMessageModalStore(this);

    reaction(
      () => this.filters.params,
      params => {
        if (!this.collection?.id) return;
        this.store.memDb.settings.setCollectionsViewPageParams(this.collection.id, params);
      }
    );

    reaction(
      () => ({ collection: this.collection, amIMounted: this.amIMounted }),
      ({ collection: maybeCollection, amIMounted }, { collection: previousCollection }) => {
        const collection = getObjectAfterDeletion({
          current: maybeCollection,
          previous: previousCollection,
          active: amIMounted,
        });

        this.initialize();
        this.setCollectionObservable(collection);
        this.filters.setExcludeCollection([collection]);
      }
    );

    reaction(
      () => this.collection?.canAccess,
      (canAccess, previousCanAccess) => {
        const couldAccessCollectionOnMount = (() => {
          // Getting access later is fine.
          if (canAccess) return true;
          // Prefer the previous value if it's already set.
          if (typeof previousCanAccess === "boolean") return previousCanAccess;
          // Either undefined (_unknown_) or false.
          return canAccess;
        })();
        this.setCouldAccessCollectionOnMount(couldAccessCollectionOnMount);
      }
    );

    reaction(
      () => this.isSubscriptionInitialized,
      initialized => {
        if (initialized) setTimeout(() => runInAction(() => (this.isLoading = false)), 100);
      }
    );

    onBecomeObserved(this, "subscribedNoteRows", () => this.initializeLiveQuery());
  }

  setCollectionObservable(collectionObservable?: CollectionObservable) {
    this.collectionObservable = collectionObservable;
  }

  setCouldAccessCollectionOnMount(couldAccessCollectionOnMount?: boolean) {
    this.couldAccessCollectionOnMount = couldAccessCollectionOnMount;
  }

  get amIMounted() {
    return this.mountState.isMounted;
  }

  get showNotFound() {
    if (!this.couldAccessCollectionOnMount) return true;

    if (this.leaveCollectionMessageModal.isModalOpen) return false;

    return !this.canAccessCollection;
  }

  get canAccessCollection(): boolean {
    // Return true when it's unknown.
    if (!this.mountState.isMounted || !this.collectionId) return true;

    const doesNotExist = this.store.collections.doesNotExist(this.collectionId);
    if (doesNotExist) return false;

    const canAccess = this.store.collections.get(this.collectionId)?.canAccess;

    return canAccess !== false;
  }

  get collectionId(): Maybe<Uuid> {
    return this.overrideCollectionId ?? this.store.routing.collectionIdParam ?? undefined;
  }

  get collection(): Maybe<CollectionObservable> {
    if (!this.mountState.isMounted) return;

    if (!this.collectionId) return;
    return this.store.collections.get(this.collectionId);
  }

  get hasNoItems() {
    return !this.isLoading && this.subscribedNoteRows.length === 0;
  }

  get collectionMultiSelectActionsContext() {
    return this.collection;
  }

  private generateItemDropdown({
    noteObservable,
  }: {
    noteObservable: INoteObservable;
  }): MdsItemDropdown {
    return {
      items: [
        {
          id: `remove-collection-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Remove note from collection",
          onClick: () => {
            if (!this.collection) return;
            if (this.collection.isShared) {
              this.removeNotesFromSharedCollectionModal.open({
                notes: [noteObservable],
                collection: this.collection,
              });
            } else {
              actions.removeNoteFromCollection({
                note: noteObservable,
                collection: this.collection,
                store: this.store,
              });
            }
          },
          iconKind: MdsIconKind.Exit,
        },
        {
          id: "divider-1",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `share-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Share",
          onClick: () =>
            this.shareSheetModal.open({
              id: noteObservable.id,
              entityKind: ShareSheetEntityKind.Note,
              eventContext: EventContext.CollectionRowActions,
            }),
          iconKind: MdsIconKind.Share,
        },
        {
          id: `copy-link-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => actions.copyNoteLinkToClipboard({ noteId: noteObservable.id }),
          iconKind: MdsIconKind.Copy,
        },
        {
          id: `favorite-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          iconKind: noteObservable.isFavorited ? MdsIconKind.ThumbtackSolid : MdsIconKind.Thumbtack,
          label: noteObservable.isFavorited ? "Unpin" : "Pin",
          onClick: async () => await noteObservable.toggleFavorite(),
        },
        {
          id: "divider-2",
          kind: MdsDropdownItemKind.Divider,
        },
        {
          id: `move-to-trash-${noteObservable.id}`,
          kind: MdsDropdownItemKind.Button,
          label: "Delete",
          onClick: async () => {
            if (noteObservable.isShared) {
              this.deleteSharedNotesModal.open(noteObservable);
            } else {
              await noteObservable.moveToTrash();
            }
          },
          iconKind: MdsIconKind.Trash,
        },
      ],
    };
  }

  private generateItemRow(
    collectionId: string,
    itemId: string,
    item: INoteObservable,
    dateLabel: string
  ): MdsItemListItemRowData {
    const collectionItemId = resolveCollectionItemSyncModelUuid({
      collectionId,
      itemId,
    });
    const collectionItem = this.store.collectionItems.get(collectionItemId);
    return {
      key: itemId,
      type: MdsItemListRowType.Item,
      size: MdsItemListSize.XLarge,
      payload: {
        id: item.id,
        kind: MdsItemKind.Note,
        createPreviewState: () =>
          new ItemPreviewState({
            store: this.store,
            id: item.id,
            kind: MdsItemKind.Note,
          }),
        label: item.title,
        onClick: () => this.store.navigation.goToNote({ noteId: itemId }),
        sharedBy: collectionItem?.addedToCollectionBy,
        sharedByVariant: MdsSharedByVariant.AddedToCollectionBy,
        dateLabel: generateShortDateString(dateLabel),
        dropdown: this.generateItemDropdown({ noteObservable: item }),
        listState: this.listState,
        extraRows: [
          {
            id: `note-${item.id}-content`,
            content: () => (
              <MdsItemListRowFeaturedContent
                collections={sortCollectionsForChips(item.collectionList?.allCollections || [])}
                snippet={
                  item.collectionList?.allCollections.length
                    ? undefined
                    : [{ text: item.secondaryTitle || "No additional text" }]
                }
              />
            ),
          },
        ],
      },
    };
  }

  get groupedLensItems() {
    return groupLens(
      this.subscribedNoteRows.map(tuple => ({
        dateTime: new Date(tuple.length === 4 ? tuple[2] : tuple[1]),
        item: tuple,
      }))
    );
  }

  get itemRows(): MdsItemListRowData[] {
    const output: MdsItemListRowData[] = [];
    if (!this.collection) {
      return output;
    }
    if (this.collection.canWrite) {
      output.push({
        key: "add-note-to-collection",
        type: MdsItemListRowType.ActionButton,
        payload: {
          label: "New note in collection",
          icon: MdsIconKind.Plus,
          onClick: async () => await this.handleCreateNewNote(),
          listState: this.listState,
        },
      });
    }

    for (const group of this.groupedLensItems) {
      output.push({
        type: MdsItemListRowType.SectionHeader,
        key: group.title,
        payload: { title: group.title },
      });

      for (const item of group.items) {
        const tuple = item.item;
        output.push({
          type: MdsItemListRowType.AsyncNote,
          key: tuple.length === 4 ? tuple[3] : tuple[2],
          size: MdsItemListSize.Medium,
          payload: {
            noteId: tuple.length === 4 ? tuple[3] : tuple[2],
            itemRow: note =>
              getRowForNoteObservable({
                dateTime: item.dateTime,
                dropdown: this.generateItemDropdown({ noteObservable: note }),
                inMainPanel: true,
                listState: this.listState,
                noteObservable: note,
                store: this.store,
              }),
          },
        });
      }
    }
    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });
    return output;
  }

  // @deprecated unless we support more than just notes here
  get collectionItemRows(): MdsItemListRowData[] {
    if (!this.collection) return [];
    const collectionId = this.collection.id;

    const output: MdsItemListRowData[] = [];

    if (this.collection.canWrite) {
      output.push({
        key: "add-note-to-collection",
        type: MdsItemListRowType.ActionButton,
        payload: {
          label: "Create note in collection",
          icon: MdsIconKind.ComposeInline,
          onClick: async () => await this.handleCreateNewNote(),
          listState: this.listState,
        },
      });
    }

    for (const tuple of this.subscribedNoteRows) {
      const noteId = tuple.length === 4 ? tuple[3] : tuple[2];
      const dateLabel = tuple.length === 4 ? tuple[2] : tuple[1];
      output.push({
        type: MdsItemListRowType.AsyncNote,
        key: noteId,
        size: MdsItemListSize.XLarge,
        payload: {
          noteId,
          itemRow: (item: INoteObservable) =>
            this.generateItemRow(collectionId, item.id, item, dateLabel),
        },
      });
    }

    output.push({
      type: MdsItemListRowType.Padding,
      key: "padding",
      payload: { height: 50 },
    });
    return output;
  }

  get orderedItemIds() {
    return this.itemRows.map(row => row.key);
  }

  // HEADER
  get headerDropdownButtonContentList(): MdsDropdownContentList {
    const optionalActions: MdsDropdownItem[] = this.collection?.isOwnedByMe
      ? [
          { id: "collection-divider", kind: MdsDropdownItemKind.Divider },
          {
            id: "delete-collection",
            kind: MdsDropdownItemKind.Button,
            label: "Delete",
            onClick: () => this.deleteCollectionModal.open({ collectionId: this.collection?.id }),
            iconKind: MdsIconKind.Trash,
          },
        ]
      : [
          {
            id: "leave-collection",
            kind: MdsDropdownItemKind.Button,
            label: "Leave collection",
            onClick: async () => await this.handleLeaveCollection(),
            iconKind: MdsIconKind.Trash,
          },
        ];

    if (this.collection?.isShared && this.collection.isOwnedByMe) {
      optionalActions.splice(1, 0, {
        id: "make-collection-private",
        kind: MdsDropdownItemKind.Button,
        label: "Make this collection private",
        onClick: () => this.makeCollectionPrivateModal.open({ collectionId: this.collection?.id }),
        iconKind: MdsIconKind.Lock,
      });
    }

    return {
      items: [
        {
          id: "copy-collection-link",
          kind: MdsDropdownItemKind.Button,
          label: "Copy link",
          onClick: () => {
            if (!this.collection) return;
            actions.copyCollectionLinkToClipboard({ collectionId: this.collection.id });
          },
          iconKind: MdsIconKind.Copy,
        },
        ...optionalActions,
      ],
    };
  }

  public async handleCreateNewNote() {
    if (!this.collection) {
      logger.debug({
        message: "handleCreateNewNote: collection not found",
      });

      return;
    }

    const collectionId = this.collection.id;
    const noteId = uuidModule.generate();
    const noteContentId = resolveNoteContentDocumentSyncModelUuid({ noteId });

    await this.store.notes.createNote({ noteId, eventContext: EventContext.CollectionActions });
    const [note, _noteContent] = await Promise.all([
      this.store.notes.getAsync(noteId),
      this.store.noteContentDocuments.getAsync(noteContentId),
    ]);

    if (!note) {
      logger.debug({
        message: "handleCreateNewNote: noteObservable not found upon creation",
        info: { noteId },
      });
      return;
    }

    // Delay adding to the collection to avoid a flash on the page
    // This is not problematic since the note is already guaranteed to exist when we navigate to the editor
    setTimeout(() => {
      note.collectionList?.addCollection({
        collectionId,
        eventContext: EventContext.CollectionActions,
      });
    }, 50);

    this.store.navigation.goToNote({ noteId, autoFocus: true });
  }

  public async handleLeaveCollection() {
    const mySpaceAccountId = this.store.spaceAccounts.myPersonalSpaceAccountId;
    if (!this.collection || !mySpaceAccountId) return;
    await this.collection.revokeAccessViaSpaceAccount({
      targetSpaceAccountId: mySpaceAccountId,
    });
    this.store.navigation.goToCollections();
  }

  useEffects() {
    this.mountState.useEffects();
  }

  setCollectionId = ({ collectionId }: { collectionId?: string }) => {
    this.overrideCollectionId = collectionId;
  };

  private initializeLiveQuery() {
    reaction(
      () => ({
        collectionId: this.collectionId,
        params: this.filters.params,
      }),
      ({ collectionId, params }) => {
        if (!collectionId) {
          this.subscribedNoteRows = [];
          this.isSubscriptionInitialized = false;
          return;
        }

        const { sortBy, lens, filter } = params;
        const collectionItemAddedAt: Record<string, string> = {};
        const dateRange = this.filters.dateSelectedRange;
        const sortByKind = sortBy as SortByKind;
        this.isLoading = true;

        this.liveQuerySubscription?.unsubscribe();
        this.liveQuerySubscription = liveQuery(async () => {
          // Collect Collection Items first, applying AddedBy/At filter and AddedAt Sort if necessary
          const collectionItemTuples = await Dexie.waitFor(
            this.store.collectionItems.search({
              collectionId,
              sortBy: SortByKind.LastAdded,
              filter: this.getCollectionItemsFilter(sortByKind),
            })
          );

          // Collect Note Ids and AddedAt
          const ids: string[] = [];
          collectionItemTuples.forEach(tuple => {
            collectionItemAddedAt[tuple[1]] = tuple[2];
            ids.push(tuple[1]);
          });

          if (ids.length === 0) {
            return [];
          }

          // TODO: for now its only notes but in future CollectionItems store should be able to handle this
          const filterOption = NotesListFilter.dateFilterMap[sortBy];

          return this.store.notes.search({
            lens,
            sortBy: this.getNotesSort(sortByKind),
            limit: 10000, // TODO: refactor to use CollectionItems store and better indexing
            filter: {
              ...filter,
              ids,
              [filterOption]: {
                from: dateRange?.start?.toDate(getLocalTimeZone()).toString(),
                to: dateRange?.end?.add({ days: 1 }).toDate(getLocalTimeZone()).toString(),
                olderThan: filter?.date?.olderThan,
              },
              collections: filter?.collections ? [filter.collections] : undefined,
            },
          });
        }).subscribe({
          next: tuples => {
            runInAction(() => {
              this.subscribedNoteRows = tuples;
              this.isLoading = false;

              // substitute date field with added_at which not part of the note model
              // TODO: we can do better than this by indexing more on Collection item
              if (sortByKind === SortByKind.LastAdded) {
                this.subscribedNoteRows = tuples
                  .map(tuple => [tuple[0], collectionItemAddedAt[tuple[2]] || "", tuple[2]])
                  .sort(
                    (a, b) => new Date(b[1]).getTime() - new Date(a[1]).getTime()
                  ) as NotesIndexTuple[];
              }

              this.isSubscriptionInitialized = true;
            });
          },
        });
      },
      { fireImmediately: true }
    );
  }

  private getCollectionItemsFilter(sortBy: SortByKind) {
    const filter = this.filters.params.filter;
    const dateRange = this.filters.dateSelectedRange;

    if (sortBy !== SortByKind.LastAdded) {
      return {
        addedBy: filter?.addedBy,
      };
    }

    return {
      addedBy: filter?.addedBy,
      addedAt: {
        from: dateRange?.start?.toDate(getLocalTimeZone()).toString(),
        to: dateRange?.end?.add({ days: 1 }).toDate(getLocalTimeZone()).toString(),
        olderThan: filter?.date?.olderThan,
      },
    };
  }

  private getNotesSort(sortBy: SortByKind): NotesSortByKind {
    // notes search doesn't support sortByKind.LastAdded
    if (sortBy === SortByKind.LastAdded) {
      return SortByKind.LastModified;
    }

    return sortBy;
  }

  async initialize() {
    if (!this.collection?.id) {
      return;
    }

    const [localDBParams, memDbParams] = await Promise.all([
      localDb.settings.getCollectionsViewPageParams(this.collection.id),
      this.store.memDb.settings.getCollectionsViewPageParams(this.collection.id),
    ]);

    if (localDBParams?.sortBy && this.store.memDb) {
      await this.store.memDb.settings.setCollectionsViewPageParams(
        this.collection.id,
        localDBParams
      );

      await localDb.settings.removeCollectionsViewPageParams(this.collection.id);
    }

    this.filters.clearAllFilters();
    this.filters.setParams({
      lens: localDBParams?.lens ?? memDbParams?.lens ?? NotesListFilter.DEFAULT_LENS,
      sortBy: localDBParams?.sortBy ?? memDbParams?.sortBy ?? NotesListFilter.DEFAULT_SORT_BY,
      filter: localDBParams?.filter ?? memDbParams?.filter,
    });
  }
}
