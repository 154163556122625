import Icons from "@/assets/icons/Icons";
import { MdsPanelHeader } from "@/design-system/components/panel-header";
import { MdsTextSize, MdsTextWeight } from "@/design-system/components/text";
import { useAppStore } from "@/store";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

interface ChatHeaderProps {
  inSidePanel?: boolean;
  isGuidedChat_experimental?: boolean;
}

export const ChatHeader = observer<ChatHeaderProps>(
  ({ inSidePanel, isGuidedChat_experimental }) => {
    const { store } = useAppStore();
    const isOnTopOfMainPanel =
      store.sidePanel.isSidePanelOpen && !store.sidePanel.canRenderSplitView;
    const shouldRenderBackButton = (inSidePanel && isOnTopOfMainPanel) || isGuidedChat_experimental;

    return (
      <MdsPanelHeader
        heading="Mem"
        headingTextProps={{
          size: MdsTextSize.Small,
          lineHeight: MdsTextSize.XSmall,
          weight: MdsTextWeight.Medium,
        }}
        icon={<MemIcon />}
        onBackClick={
          shouldRenderBackButton
            ? isGuidedChat_experimental
              ? store.navigation.goToGuidedChatHome
              : store.sidePanel.goUp
            : undefined
        }
      />
    );
  }
);

const MemIcon = styled(Icons.Mem)({
  height: "32px",
  width: "32px",
});
