import { BaseSyncModelStore } from "@/store/sync/BaseSyncModelStore";
import { SyncModelKind, SyncUpdateValue } from "@/store/sync/types";
import { AppSubStoreArgs } from "@/store/types";
import { SpaceAccountFeatureFlagsObservable } from "./FeatureFlagsObservable";
import { SpaceAccountFeatureFlagsModelData } from "./types";
import { computed, makeObservable, onBecomeObserved } from "mobx";

type PostHogFeatureFlagIds = "related-notes" | "copilot" | "guided-chat";

export class AppStoreSpaceAccountFeatureFlagsStore extends BaseSyncModelStore<
  SpaceAccountFeatureFlagsObservable,
  SpaceAccountFeatureFlagsModelData
> {
  private lazyFlags: Record<string, boolean> | undefined;

  constructor(injectedDeps: AppSubStoreArgs) {
    super({ modelKind: SyncModelKind.SpaceAccountFeatureFlagsConfig, ...injectedDeps });

    makeObservable<AppStoreSpaceAccountFeatureFlagsStore, "lazyFlags">(this, {
      createSyncModel: false,
      featureFlags: computed,
      lazyFlags: true,
      getFlagStatus: false,
    });

    // Feature Flags are design to be "locked in" after first access by design.
    // Hence we only read them once and save the result to a local object called lazyFlags.
    onBecomeObserved(this, "featureFlags", () => {
      this.localTable
        .limit(1) // A Space Account has just 1 Feature Flags record
        .toArray()
        .then(res => {
          if (res.length > 0 && this.lazyFlags === undefined) {
            // The single Feature Flags record has a map of flag_id to boolean, like { "feature-1": true, "feature-2": false }
            // We keep them in a POJO in this store as well.
            const plainObject = Object.assign({}, res[0]?.model_data?.flags);
            this.lazyFlags = plainObject;
          } else {
            this.lazyFlags = {};
          }
        });
    });
  }

  createSyncModel(
    data: SyncUpdateValue<SpaceAccountFeatureFlagsModelData>
  ): SpaceAccountFeatureFlagsObservable {
    return new SpaceAccountFeatureFlagsObservable({
      id: data.model_id,
      data,
      store: this.store,
    });
  }

  // Feature flags are "locked in" after first access by design.
  // They will not change until the user refreshes the page.
  get featureFlags(): Record<string, boolean> {
    return this.lazyFlags ?? {};
  }

  getFlagStatus(flag: PostHogFeatureFlagIds): boolean {
    const val = this.featureFlags[flag];
    return val ?? false;
  }
}
