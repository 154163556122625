import { FC } from "react";
import { observer } from "mobx-react-lite";
import EmptyCollections from "@/assets/images/empty-collections.svg";
import { EmptyContainer } from "@/components/styling/empty";
import { CollectionViewPageListEmptyButton } from "@/pages/collections-view/components/CollectionViewPageListEmptyButton";

interface Props {
  isSidePanel: boolean;
}

export const CollectionViewPageListEmpty: FC<Props> = observer(({ isSidePanel }) => {
  return (
    <EmptyContainer>
      <img src={EmptyCollections} alt="No collections" />
      <CollectionViewPageListEmptyButton isSidePanel={isSidePanel} />
    </EmptyContainer>
  );
});
