import classNames from "classnames";
import { useCallback, FC, useMemo, useRef } from "react";
import { css, cx } from "@/domains/emotion";
import { mdsColors } from "@/design-system/foundations/colors";
import { mdsFontSizes, mdsSpacings } from "@/design-system/foundations/typography";
import { MdsItemListRowAction } from "@/design-system/components/item-list/MdsItemListRowAction";
import { MdsItemListRowDropdown } from "@/design-system/components/item-list/MdsItemListRowDropdown";
import {
  MdsItemListItemRowData,
  MdsItemListSize,
} from "@/design-system/components/item-list/types";
import { ReactMouseEvent } from "@/domains/react/types";
import { MdsSpacer } from "@/design-system/components/spacer";
import { MdsText } from "@/design-system/components/text";
import { MdsTextAlign, MdsTextSize } from "@/design-system/components/text/types";
import { observer } from "mobx-react-lite";
import { MdsItemListItemSharedByBox } from "@/design-system/components/item-list/rows/MdsItemListItemSharedByBox";
import { isMac } from "@/domains/platform/isMac";
import { Icon } from "@/design-system/components/item-list/rows/icon/icon";
import styled from "@emotion/styled";
import { TitleIconComponent } from "@/design-system/components/item-list/rows/title-icon/title-icon";

export interface MdsItemListItemRowProps {
  data: MdsItemListItemRowData;
  className?: string;
}

export const MdsItemListItemRow: FC<MdsItemListItemRowProps> = observer(
  ({
    data: { payload: item, size = MdsItemListSize.Medium, mustClickOnRowTitle = false },
    className,
  }) => {
    const itemPreviewState = useMemo(() => item.createPreviewState?.(), [item]);

    const rowSizeStyle = rowSizeStyleMapping[size];
    const pointerStyles = css({
      cursor: mustClickOnRowTitle ? "default" : "pointer",
    });
    const alignmentStyles = css({
      alignItems: "center",
    });
    const combinedStyles = cx(wrapperStyles, rowSizeStyle, pointerStyles, alignmentStyles);

    const handleMouseEnter = item.listState?.isHoverable
      ? () => item.listState?.highlightItem(item.id)
      : undefined;

    const isHighlighted = item.id === item.listState?.highlightedItemId;
    const isSelected = item.listState?.isSelected(item.id);

    const itemRef = useRef(item);
    itemRef.current = item;

    const handleItemClick = useCallback((event: ReactMouseEvent, canClickItem = true) => {
      event.stopPropagation();
      const item = itemRef.current;
      if (event.shiftKey) {
        item.listState?.selectItemsFromLastSelectionUntil(item.id);
        return;
      }

      if (item.listState?.isSelectModeActive || (isMac() ? event.metaKey : event.ctrlKey)) {
        item.listState?.toggleItemSelection(item.id);
        return;
      }

      if (canClickItem) {
        item.onClick({ itemId: item.id });
      }
    }, []);

    const handleRowClick = (event: ReactMouseEvent) => handleItemClick(event, !mustClickOnRowTitle);

    const handleTitleClick = (event: ReactMouseEvent) => handleItemClick(event);

    const showActions =
      (!item.listState?.isSelectModeActive || !isSelected) &&
      isHighlighted &&
      (item.action || item.dropdown);

    const anchorPreviewAtTheEnd = itemPreviewState?.anchorPreviewAtTheEnd;
    const preview = itemPreviewState?.previewUi ?? null;

    return (
      <div
        className={classNames(
          combinedStyles,
          isSelected && "selected",
          isHighlighted && "highlighted",
          item.isSpecial && "special",
          "mds-item-list-row",
          className
        )}
        onClick={handleRowClick}
        onMouseEnter={handleMouseEnter}
      >
        <Icon
          item={item}
          isSelected={isSelected}
          isHighlighted={isHighlighted}
          itemRef={itemRef}
          handleItemClick={handleItemClick}
        />
        <div className={cx(contentColumnStyles, marginTopStyles)}>
          <div className={cx(contentRowStyles, !item.extraRows?.length && bottomSpacingStyles)}>
            <div className={contentStyles}>
              <Label
                hasIcon={!!item.titleIcon}
                onClick={handleTitleClick}
                onMouseEnter={itemPreviewState?.handleMouseEnter}
                onMouseLeave={itemPreviewState?.handleMouseLeave}
              >
                {item.label}
              </Label>
              {!!item.titleIcon && <TitleIconComponent titleIcon={item.titleIcon} />}
            </div>
          </div>
          {item.extraRows?.map(row => (
            <div
              key={row.id}
              className={cx(contentRowStyles, bottomSpacingStyles)}
              onMouseEnter={itemPreviewState?.handleMouseEnter}
              onMouseLeave={itemPreviewState?.handleMouseLeave}
            >
              {row.content?.()}
            </div>
          ))}
        </div>
        {item.rightAlignedComponent}
        <MdsItemListItemSharedByBox
          sharedBy={item.sharedBy}
          sharedByVariant={item.sharedByVariant}
        />
        {!anchorPreviewAtTheEnd && preview}
        {(!!showActions || item.dateLabel) && (
          <div className={actionStyles}>
            <MdsSpacer />
            {!showActions && (
              <MdsText
                size={MdsTextSize.XXSmall}
                align={MdsTextAlign.Right}
                color={mdsColors().grey.x500}
              >
                {item.dateLabel || ""}
              </MdsText>
            )}
            {!!showActions && (
              <>
                {item.action && <MdsItemListRowAction itemId={item.id} action={item.action} />}
                {item.dropdown && (
                  <MdsItemListRowDropdown itemId={item.id} dropdown={item.dropdown} />
                )}
              </>
            )}
          </div>
        )}
        {anchorPreviewAtTheEnd && preview}
      </div>
    );
  }
);

const wrapperStyles = css({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingRight: mdsSpacings().smd,
  paddingTop: mdsSpacings().xs,
  paddingBottom: mdsSpacings().xs,

  borderRadius: 0,
  "&.special": {
    animation: "specialFadeIn 2000ms ease-in",
  },
  "&.selected": {
    backgroundColor: mdsColors().selectedBackground,
    borderRadius: "8px",
    transition: "0.2s all",
  },
  "&:hover, &.highlighted": {
    backgroundColor: mdsColors().grey.x25,
    borderRadius: "8px",
    transition: "0.2s all",
  },
  "&:active": {
    backgroundColor: mdsColors().grey.x25,
  },
  userSelect: "none",
  "@keyframes specialFadeIn": {
    from: { backgroundColor: "#FDFBFE" },
    to: { backgroundColor: "inherit" },
  },
});

const contentColumnStyles = css({
  display: "flex",
  flexDirection: "column",
  lineHeight: mdsFontSizes().medium,
  flex: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  gap: mdsSpacings().xs,
});

const contentRowStyles = css({
  display: "flex",
  width: "100%",
});

const bottomSpacingStyles = css({
  marginBottom: "8px",
});

const contentStyles = css({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  height: "100%",
  alignItems: "center",
});

interface LabelProps {
  hasIcon: boolean;
}

const Label = styled.div<LabelProps>(({ theme, hasIcon }) => ({
  fontSize: theme.fontSizes.small,
  color: theme.colors.grey.x600,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: hasIcon ? "auto" : "100%",
  overflow: "hidden",
  cursor: "pointer",
  fontWeight: theme.fontWeights.medium,
  lineHeight: theme.lineHeights.xsmall,
}));

const actionStyles = css({
  alignItems: "flex-end",
  display: "flex",
  marginLeft: "10px",
  width: "60px",
});

const marginTopStyles = css({
  marginTop: "8px",
});

const rowSizeStyleMapping: { [key in MdsItemListSize]: string } = {
  [MdsItemListSize.Medium]: css({
    height: 48,
  }),
  [MdsItemListSize.Large]: css({
    height: 56,
  }),
  [MdsItemListSize.XLarge]: css({
    minHeight: "60px",
  }),
};
