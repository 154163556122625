import { MdsButton, MdsButtonSize, MdsButtonVariant } from "@/design-system/components/button";
import { MdsText } from "@/design-system/components/text";
import { MdsTextOverflow } from "@/design-system/components/text/types";
import { mdsBorderRadius, mdsColors, mdsFontSizes } from "@/design-system/foundations";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import styled from "@emotion/styled";
import { MdsIcon, MdsIconKind } from "@/design-system/components/icon";

interface ImportInProgressBannerProps {
  handleGoToImports: () => void;
}

export const ImportInProgressBannerContent: FC<ImportInProgressBannerProps> = observer(
  ({ handleGoToImports }) => {
    return (
      <Wrapper>
        <Container>
          <LeftSection>
            <MdsIcon kind={MdsIconKind.SpinnerSolid} />
            <StyledText overflow={MdsTextOverflow.Ellipsis}>An import is in progress</StyledText>
          </LeftSection>
          <RightSection>
            <MdsButton
              size={MdsButtonSize.Small}
              variant={MdsButtonVariant.Transparent}
              label="Go to Imports"
              onClick={handleGoToImports}
            />
          </RightSection>
        </Container>
      </Wrapper>
    );
  }
);

const Wrapper = styled.div({
  height: "48px",
  width: "100%",
  padding: "0 16px",
});

const Container = styled.div({
  alignItems: "center",
  background: mdsColors().grey.x50,
  color: mdsColors().grey.x600,
  borderRadius: mdsBorderRadius().mediumLarge,
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "space-between",
  padding: "0 10px",
});

const LeftSection = styled.div({
  marginLeft: "2px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "8px",
});

const RightSection = styled.div({
  display: "flex",
  alignItems: "center",
});

const StyledText = styled(MdsText)({
  color: mdsColors().grey.x600,
  fontSize: mdsFontSizes().small,
  lineHeight: mdsFontSizes().medium,
  userSelect: "none",
});
