import { observer } from "mobx-react-lite";
import { FC } from "react";
import { ImportInProgressBanner } from "@/components/banners";
import { AppCalloutBanner } from "@/components/banners/app-callout-banner";

export const PageBannerSection: FC = observer(() => {
  return (
    <>
      <AppCalloutBanner />
      <ImportInProgressBanner />
    </>
  );
});
